<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'
import ValidationMessage from '../../../../components/form/ValidationMessage'
import PlanRuleTypeLabel from './labels/PlanRuleTypeLabel'
import PlanRuleTargetLabel from './labels/PlanRuleTargetLabel'
import PlanRuleValueLabel from './labels/PlanRuleValueLabel'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'

import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required, requiredIf} from 'vuelidate/lib/validators'
import {clone} from 'lodash'
import {mapGetters} from 'vuex'

const defaultPlanRule = {
  type: 'amount',
  target: '',
  value: 0,
  items: [],
  description: '',
  renew_credits: false,
}

export default {
  components: {
    ListHeader,
    FormSaveButton,
    FormCancelButton,
    ValidationMessage,
    PlanRuleTypeLabel,
    PlanRuleTargetLabel,
    PlanRuleValueLabel
  },
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      updateInputMoney: false,
      updateRuleCurrency: false,
      hasPayment: false,
      skipUpdateRuleType: false,
      form: {
        coach_id: '',
        type: 'custom',
        name: '',
        bg_color: null,
        description: '',
        amount: 0,
        amount_single: false,
        interval: null,
        interval_type: null,
        recurrent: false,
        status: 'created',
        rules: [],
        active_clients: [],
        show_client_dot: false,
      },
      currentRule: clone(defaultPlanRule),
      dependencies: {
        coaches: [],
        default_plans: [],
        interval_types: [],
        plan_rule_types: [],
        plan_rule_targets: [],
      }
    }
  },
  validations: {
    form: {
      name: {required},
      amount: {required},
    },
    currentRule: {
      type: {required},
      target: {
        required: requiredIf(function (data) {
          return data.type !== 'credit'
        })
      },
      value: {required},
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasBasicPlan', 'hasProfessionalPlan']),
    moneyMask() {
      return currencyConfig()
    },
    planDisabled() {
      return this.form.active_clients.length > 0
    },
    planCustom() {
      return this.form.type === 'custom'
    },
    isLessonPlan() {
      return ['lesson_single', 'lesson_pack', 'lesson_fixed'].includes(this.form.type)
    },
    currentRuleValueLabel() {
      if (this.currentRule.type === 'amount') {
        return 'Valor'
      } else if (this.currentRule.type === 'percent') {
        return 'Valor %'
      }
      return 'Quantidade'
    },
    currentRuleValueMask() {
      if (this.currentRule.type === 'amount') {
        return this.moneyMask
      }
      return null
    },
    canAddRule() {
      if (this.form.type !== 'custom') {
        return this.form.rules.length === 0
      }
      return true
    },
    planAmount() {
      const creditRule = this.form.rules.find(item => item.type === 'credit')
      if (['schedule_credit', 'lesson_single'].includes(this.form.type) && this.form.rules.length === 1 && creditRule) {
        return creditRule.value * this.currencyParser(this.form.amount)
      }
      return this.currencyParser(this.form.amount)
    },
    filteredPlanRuleTargets() {
      return this.dependencies.plan_rule_targets.filter(item => {
        if (this.hasBasicPlan && !this.hasProfessionalPlan && ['product', 'schedule'].includes(item.id)) {
          return false
        }
        if (this.currentRule.type === 'credit') {
          return ['schedule', 'lesson'].includes(item.id)
        } else if (this.currentRule.type === 'amount') {
          return ['product', 'registration', 'schedule'].includes(item.id)
        } else if (this.currentRule.type === 'percent') {
          return ['product', 'registration', 'schedule'].includes(item.id)
        }
        return true
      })
    },
    dependenciesDefaultPlansFiltered() {
      return this.dependencies.default_plans.filter(item => {
        if (!this.hasBasicPlan && !this.hasProfessionalPlan) {
          return ['members_stage'].includes(item.id)
        }
        return true
      })
    },
    filteredDependenciesPlanRuleTypes() {
      return this.dependencies.plan_rule_types.filter(item => {
        if (!this.hasBasicPlan && !this.hasProfessionalPlan) {
          return ['percent', 'amount'].includes(item.id)
        }
        return true
      })
    },
  },
  watch: {
    'form.type': function (value) {
      if (!this.isEdit) {
        this.skipUpdateRuleType = true
        this.form.rules.splice(0)
        this.form.recurrent = false
        this.form.amount_single = false
        this.form.show_client_dot = false
        this.hasPayment = true
        this.updateRuleCurrency = true
        if (value === 'members_stage') {
          this.hasPayment = false
          this.currentRule.type = 'amount'
          this.currentRule.value = 0
          setTimeout(() => {
            this.currentRule.target = 'registration'
          }, 50)
        } else if (value === 'schedule_fixed') {
          this.form.show_client_dot = true
          this.form.recurrent = true
          this.currentRule.type = 'credit'
          this.currentRule.target = 'schedule'
          this.currentRule.value = 4
        } else if (value === 'schedule_credit' || value === 'lesson_single') {
          this.form.amount_single = true
          this.currentRule.type = 'credit'
          this.currentRule.target = value === 'schedule_credit' ? 'schedule' : 'lesson'
          this.currentRule.value = 1
        } else if (value === 'lesson_pack') {
          this.hasPayment = true
          this.currentRule.type = 'credit'
          this.currentRule.target = 'lesson'
          this.currentRule.value = 10
        } else if (value === 'lesson_fixed') {
          this.form.recurrent = true
          this.currentRule.type = 'credit'
          this.currentRule.target = 'lesson'
          this.currentRule.value = 4
        } else {
          this.hasPayment = false
          this.currentRule.type = ''
          this.currentRule.target = ''
          this.currentRule.value = 0
        }
        setTimeout(() => {
          this.updateRuleCurrency = false
        }, 50)
        setTimeout(() => {
          this.skipUpdateRuleType = false
        }, 200)
      }
    },
    'currentRule.type': function () {
      if (!this.skipUpdateRuleType) {
        this.updateRuleCurrency = true
        if (this.form.type === 'members_stage') {
          this.currentRule.target = 'registration'
        } else {
          this.currentRule.target = ''
        }
        this.currentRule.value = 0
        setTimeout(() => {
          this.updateRuleCurrency = false
        }, 50)
      }
    },
    'form.recurrent': function (value) {
      if (value) {
        this.form.interval = 1
        this.form.interval_type = 'months'
      } else {
        this.form.interval = null
        this.form.interval_type = null
      }
    },
    'form.coach_id': function (value) {
      if (!this.isEdit) {
        this.updateInputMoney = true
        if (value && this.form.type === 'lesson_single') {
          const coach = this.dependencies.coaches.find(item => item.id === value)
          if (coach?.coach_config?.amount_class > 0) {
            this.form.amount = this.currencyFormatter(coach.coach_config.amount_class)
          }
        } else {
          this.form.amount = 0
        }
        setTimeout(() => {
          this.updateInputMoney = false
        }, 50)
      }
    },
  },
  mounted() {
    this.getDependencies()
    if (!this.hasBasicPlan && !this.hasProfessionalPlan) {
      this.form.type = 'members_stage'
    }
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('plan', null, id, null, ['rules', 'active_clients'])
          .then(response => {
            this.updateInputMoney = true
            this.form = {...response}
            this.hasPayment = this.form.amount > 0
            this.form.amount = this.currencyFormatter(this.form.amount)
            setTimeout(() => {
              this.updateInputMoney = false
            }, 50)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getDependencies() {
      DataService.get([
        {domain: 'default_plans'},
        {domain: 'interval_type'},
        {domain: 'plan_rule_type'},
        {domain: 'plan_rule_target'},
      ]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
        this.getCoaches()
      })
    },
    async getCoaches() {
      const data = {is_coach: true, query: '', only_clients: true}
      const result = await restfulService.post('client', 'autocomplete', null, data)
      this.dependencies.coaches = [...result]
    },
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      data.amount = this.hasPayment ? this.currencyParser(data.amount) : 0
      if (!data.recurrent) {
        data.interval = null
        data.interval_type = null
      }
      restfulService.save('plan', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'plan.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    },
    editRule(rule) {
      this.skipUpdateRuleType = true
      this.updateRuleCurrency = true
      this.currentRule = clone(rule)
      setTimeout(() => {
        this.updateRuleCurrency = false
        this.skipUpdateRuleType = false
      }, 100)
    },
    saveRule() {
      const ruleIndex = this.form.rules.findIndex(ruleItem => {
        return ruleItem.type === this.currentRule.type
            && ruleItem.target === this.currentRule.target
      })
      const currentRule = clone(this.currentRule)
      if (this.currentRule.type === 'amount') {
        currentRule.value = this.currencyParser(currentRule.value)
      }
      if (ruleIndex > -1) {
        this.form.rules.splice(ruleIndex, 1, currentRule)
      } else {
        this.form.rules.push(currentRule)
      }
      if (this.planCustom) {
        this.editRule(defaultPlanRule)
      }
    },
    removeRule(rule) {
      const ruleIndex = this.form.rules.findIndex(ruleItem => {
        return ruleItem.type === rule.type
            && ruleItem.target === rule.target
            && ruleItem.value === rule.value
      })
      if (ruleIndex > -1) {
        this.form.rules.splice(ruleIndex, 1)
      }
    },
  }
}
</script>

<template>
  <div>
    <ListHeader title="Planos e Pacotes"/>
    <el-alert type="warning" v-if="planDisabled" class="mb-3" :closable="false" show-icon>
      <span class="fs-16">Edição limitada, planos com clientes ativos só podem alterar a descrição e o indicador dos clientes!</span>
    </el-alert>
    <el-form ref="form"
             :model="form"
             label-position="top">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Dados do Plano</span>
            </div>
            <el-form-item label="Tipo de Plano"
                          class="is-required"
                          v-if="!isEdit">
              <el-select v-model="form.type"
                         :disabled="planDisabled"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option v-for="item in dependenciesDefaultPlansFiltered"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Professor"
                          class="is-required"
                          v-if="isLessonPlan">
              <el-select v-model="form.coach_id"
                         clearable
                         :disabled="planDisabled"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option v-for="item in dependencies.coaches"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Nome do Plano/Pacote"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.name.$error }">
              <el-input v-model="form.name"
                        @input="$v.form.name.$touch()"/>
              <validation-message v-if="$v.form.name.$error"/>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="Descrição">
                  <el-input type="textarea"
                            :rows="4"
                            v-model="form.description"/>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item class="mb-2" label="Exibir Indicador no Cliente">
                  <el-switch
                      v-model="form.show_client_dot"
                      active-color="#13ce66"
                      active-text="SIM"
                      inactive-color="#ff4949"
                      inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
                <el-form-item label="Cor do Indicador" v-if="form.show_client_dot">
                  <el-color-picker v-model="form.bg_color"></el-color-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="10">
                <el-form-item label="Gerar Cobrança">
                  <el-switch
                      v-model="hasPayment"
                      :disabled="planDisabled"
                      active-color="#13ce66"
                      active-text="SIM"
                      inactive-color="#ff4949"
                      inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="14" v-if="hasPayment">
                <el-form-item label="Valor"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.amount.$error }">
                  <el-input v-model.lazy="form.amount"
                            @input="$v.form.amount.$touch()"
                            :disabled="planDisabled"
                            v-if="!updateInputMoney"
                            v-money="moneyMask"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-alert class="mb-3" show-icon :closable="false" type="warning" v-if="hasPayment && form.amount_single">
              <div class="pb-1">Informe o valor único de cada crédito (reserva/aula)</div>
              <div class="fs-14 font-weight-bold">
                Valor Final: {{ planAmount | currencyFormatter }}
              </div>
            </el-alert>
            <el-form-item label="Renovação Automática">
              <el-switch
                  v-model="form.recurrent"
                  :disabled="planDisabled"
                  active-color="#13ce66"
                  active-text="SIM"
                  inactive-color="#ff4949"
                  inactive-text="NÃO">
              </el-switch>
            </el-form-item>
            <el-row :gutter="20" v-if="form.recurrent">
              <el-col :span="12">
                <el-form-item label="Recorrência"
                              class="is-required">
                  <el-select v-model="form.interval_type"
                             :disabled="planDisabled"
                             filterable
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full">
                    <el-option v-for="item in dependencies.interval_types"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Intervalo"
                              class="is-required">
                  <el-input-number v-model="form.interval"
                                   :disabled="planDisabled"
                                   :step="1"
                                   :min="1">
                  </el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :span="14">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Regras</span>
            </div>
            <el-row :gutter="10" v-if="!planDisabled">
              <el-col :span="8">
                <el-form-item label="Tipo"
                              class="is-required">
                  <el-select v-model="currentRule.type"
                             @input="$v.currentRule.type.$touch()"
                             size="small"
                             filterable
                             :disabled="!planCustom && form.type !== 'members_stage'"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full">
                    <el-option v-for="item in filteredDependenciesPlanRuleTypes"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Item"
                              class="is-required">
                  <el-select v-model="currentRule.target"
                             @input="$v.currentRule.target.$touch()"
                             size="small"
                             filterable
                             :disabled="!planCustom"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full">
                    <el-option v-for="item in filteredPlanRuleTargets"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="currentRuleValueLabel">
                  <el-input v-model.lazy="currentRule.value"
                            @input="$v.currentRule.value.$touch()"
                            size="small"
                            v-if="!updateRuleCurrency"
                            v-money="currentRuleValueMask"/>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-form-item label=" ">
                  <el-button type="success" size="small"
                             :disabled="isSubmitted || $v.currentRule.$invalid || !canAddRule"
                             @click="saveRule">
                    <i class="fas fa-check"></i>
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-alert class="mb-3" show-icon :closable="false" type="warning" v-if="!canAddRule && !planDisabled">
              <div class="pb-1"><strong>Planos pré-definidos</strong> só podem ter 1 regra selecionada</div>
            </el-alert>
            <h3>Regras Selecionadas</h3>
            <table class="custom-table-list">
              <thead>
              <tr>
                <th>Tipo</th>
                <th>Item</th>
                <th>Valor/Quantidade</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="rule in form.rules">
                <td>
                  <plan-rule-type-label size="mini" :type="rule.type"/>
                </td>
                <td>
                  <plan-rule-target-label size="mini" v-if="rule.target" :target="rule.target"/>
                </td>
                <td>
                  <plan-rule-value-label size="mini" :type="rule.type" :value="rule.value"/>
                </td>
                <td>
                  <template v-if="!planDisabled">
                    <el-button type="info" size="mini" @click="editRule(rule)" v-if="canAddRule">
                      <i class="fas fa-edit"></i>
                    </el-button>
                    <el-button type="danger" size="mini" @click="removeRule(rule)">
                      <i class="fas fa-times"></i>
                    </el-button>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </el-card>
        </el-col>
      </el-row>
      <el-card class="el-card__btn_form">
        <el-form-item class="m-0">
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.form.$invalid || form.rules.length === 0"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="plan.index"/>
        </el-form-item>
      </el-card>
    </el-form>
  </div>
</template>
