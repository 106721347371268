<script>
import GripoDialog from '../../../../components/common/Dialog'
import EntryStatusCircle from './labels/StatusCircle'
import EntryIntervalType from './labels/IntervalType'
import EntryStatus from './labels/StatusLabel'

import * as restfulService from '../../../../services/restfulService'
import {showInvoicePdf, showInvoiceXml} from '../../invoice/service'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'

import moment from 'moment'
import {orderBy} from 'lodash'
import {mapGetters} from "vuex";

export default {
  name: 'show-entry',
  components: {GripoDialog, EntryStatusCircle, EntryIntervalType, EntryStatus},
  mixins: [helpers],
  props: {
    entryId: {
      type: Number
    },
    entryType: {
      type: String,
      default: 'revenue'
    },
    deleted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDialog: false,
      showNfProducts: false,
      form: {
        type: ''
      }
    }
  },
  computed: {
    ...mapGetters(['hasCommercialReceiptModule', 'hasCommercialInvoiceModule']),
    isRevenue() {
      return this.entryType === 'revenue'
    },
    isSettled() {
      return !this.form.deleted_at && Array.isArray(this.form.portions) && this.form.portions.every(item => item.status === 'settled')
    },
    isPartialSettled() {
      return Array.isArray(this.form.portions)
          && this.form.portions.some(item => item.partial_settled && item.status === 'created')
    },
    hasInvoice() {
      return !!this.form.invoice
    },
    productsList() {
      const products = Array.isArray(this.form?.order?.products) ? this.form.order.products : []
      if (this.hasInvoice && this.showNfProducts) {
        return orderBy(products.map(orderProduct => {
          const invoiceItem = (this.form.invoice?.body_data?.items || [])
              .find(invoiceItem => [orderProduct.product.code, orderProduct.product_id].includes(invoiceItem.codigo_produto))
          return {
            ...orderProduct,
            ...{
              number: invoiceItem?.numero_item || null
            }
          }
        }).filter(item => item.number !== null), ['number'], ['asc'])
      }
      return products
    }
  },
  watch: {
    entryId(value) {
      if (value) {
        this.getEntry(value)
      }
    }
  },
  methods: {
    getEntry(id) {
      bus.$emit('show-loader')
      restfulService.get(`entry/${this.entryType}`, null, id, null, [
        'client', 'supplier', 'classification',
        'portions.payment_methods.payment_method',
        'portions.payment_methods.bank_account',
        'portions.deleted_by_user',
        'order.created_by_user',
        'order.products.product',
        'order.products.invoice',
        'order.products.orders',
        'invoice',
      ], this.deleted)
          .then(response => {
            response.portions.forEach((item) => {
              item.received_late = item.pay_date > item.due_date && item.status === 'settled'
              item.overdue = moment(item.due_date, 'YYYY-MM-DD') < moment() && item.status !== 'settled'
              if (item.amount_paid && item.amount_paid < item.amount) {
                item.outstanding = true
              }
            })
            this.form = {...response}
            setTimeout(() => {
              this.showDialog = true
              this.handleModalVisible()
              bus.$emit('hide-loader')
            }, 300)
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    handleModalVisible() {
      this.$emit('update:entryId', null)
    },
    showPaymentMethods(entryPortion) {
      return entryPortion.payment_methods.map(item => item.payment_method.description).join(', ')
    },
    showPaymentMethodsAmountPaid(entryPortion) {
      return entryPortion.payment_methods.reduce((total, item) => {
        return total + item.amount
      }, 0)
    },
    balanceUsed(entryPortion) {
      if (entryPortion.payment_methods && entryPortion.payment_methods.length) {
        return entryPortion.payment_methods
            .filter(item => item.payment_method.key === 'saldo')
            .reduce((total, item) => {
              return total + item.amount
            }, 0)
      }
      return 0
    },
    async showInvoicePdf(invoice) {
      try {
        bus.$emit('show-loader')
        const result = await showInvoicePdf(invoice)
        if (invoice.type === 'nfse') {
          this.pdfBase64String = result.base64
        }
        bus.$emit('hide-loader')
      } catch (e) {
        console.log(e)
        bus.$emit('hide-loader')
      }
    },
    async showInvoiceXml(invoice) {
      bus.$emit('show-loader')
      try {
        await showInvoiceXml(invoice)
        bus.$emit('hide-loader')
      } catch (e) {
        console.log(e)
        bus.$emit('hide-loader')
      }
    }
  }
}
</script>

<template>
  <gripo-dialog :model.sync="showDialog"
                width="80vw"
                :persistent="false"
                :on-close="handleModalVisible">
    <template slot="header">Visualizar Lançamento</template>
    <template slot="content">
      <template v-if="showDialog">
        <el-row :gutter="20">
          <el-col :span="10">
            <table class="table-show-data entry-table-data">
              <tbody>
              <tr v-if="isRevenue">
                <td class="text-right"><strong>Cliente</strong>:</td>
                <td>{{ form.client.name }}</td>
              </tr>
              <tr v-else>
                <td class="text-right"><strong>Fornecedor</strong>:</td>
                <td>{{ form.supplier.name }}</td>
              </tr>
              <tr>
                <td class="text-right"><strong>Classificação</strong>:</td>
                <td>{{ form.classification.description }}</td>
              </tr>
              <tr v-if="!form.recurrent">
                <td class="text-right"><strong>Status</strong>:</td>
                <td>
                  <entry-status :item="form"
                                :status="isSettled ? 'settled' : 'created'"
                                :is-portion="false"
                                :deleted="!!form.deleted_at"
                                size="medium"/>
                  <div class="entry-status ml-1"
                       v-if="isPartialSettled">
                    <el-tag size="mini" type="success">
                      Quitado Parcialmente
                    </el-tag>
                  </div>
                  <div class="mt-1" v-if="form.deleted_at && form.portions.length">
                    <div>Em: <strong>{{ form.deleted_at | dateTimeEnToBr }}</strong></div>
                    <div>Por: <strong>{{ form.portions[0]?.deleted_by_user?.name || '' }}</strong></div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-right"><strong>Data de Vencimento</strong>:</td>
                <td>{{ form.due_date | dateEnToBr }}</td>
              </tr>
              <tr>
                <td class="text-right"><strong>Valor Total</strong>:</td>
                <td>{{ (form.amount + form.discount) | currencyFormatter }}</td>
              </tr>
              <tr>
                <td class="text-right"><strong>Desconto</strong>:</td>
                <td>{{ form.discount | currencyFormatter }}</td>
              </tr>
              <tr>
                <td class="text-right"><strong>Valor Final</strong>:</td>
                <td>{{ form.amount | currencyFormatter }}</td>
              </tr>
              <tr v-if="form.recurrent">
                <td class="text-right"><strong>Recorrência</strong>:</td>
                <td>{{ form.interval }}
                  <entry-interval-type :interval-type="form.interval_type"/>
                </td>
              </tr>
              <tr v-if="!form.recurrent && form.portions.length > 1">
                <td class="text-right"><strong>Parcelas</strong>:</td>
                <td>{{ form.portions_number }}</td>
              </tr>
              <tr>
                <td class="text-right"><strong>Descrição</strong>:</td>
                <td>
                  <template v-if="form.portions.length > 1 || form.recurrent">
                    <span class="safe-html" v-html="form.description"></span>
                  </template>
                  <template v-else-if="form.portions[0]">
                    <span class="safe-html" v-html="form.portions[0].description"></span>
                  </template>
                </td>
              </tr>
              <tr v-if="(hasCommercialReceiptModule || hasCommercialInvoiceModule) && !form.order && !!form.invoice">
                <td class="text-right"><strong>Nota Fiscal</strong>:</td>
                <td>
                  <el-button type="warning"
                             size="mini"
                             @click="showInvoicePdf(form.invoice)"
                             title="Visualizar PDF Nota">
                    <i class="fas fa-file-invoice-dollar"></i>
                    <span class="ml-2">PDF</span>
                  </el-button>
                  <el-button type="info"
                             size="mini"
                             @click="showInvoiceXml(form.invoice)"
                             title="Visualizar XML Nota">
                    <i class="fas fa-file-invoice-dollar"></i>
                    <span class="ml-2">XML</span>
                  </el-button>
                </td>
              </tr>
              </tbody>
            </table>
          </el-col>
          <el-col :span="14">
            <div class="mb-3" v-if="form.portions.length === 1 && form.portions[0].payment_methods.length">
              <h3 class="mt-0 mb-2">Pagamentos</h3>
              <table class="table-show-data">
                <thead>
                <th>Forma/Data de Pagamento</th>
                <th class="text-right">Valor</th>
                <th>Conta Bancária</th>
                <th>Observações</th>
                </thead>
                <tbody>
                <tr v-for="paymentMethod in form.portions[0].payment_methods">
                  <td>
                    Forma: <strong>{{ paymentMethod.payment_method.description }}</strong>
                    <div>Quitado em: <strong>{{ paymentMethod.created_at | dateTimeEnToBr }}h</strong></div>
                  </td>
                  <td class="text-right">{{ paymentMethod.amount | currencyFormatter }}</td>
                  <td>{{ paymentMethod.bank_account ? paymentMethod.bank_account.description : '' }}</td>
                  <td>{{ paymentMethod.notes }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <template v-if="form.order">
              <table class="table-show-data entry-table-data" v-if="isRevenue">
                <tbody>
                <tr>
                  <td class="text-right"><strong>Comanda nº</strong>:</td>
                  <td>{{ form.order.number }}</td>
                </tr>
                <tr>
                  <td class="text-right"><strong>Criada em</strong>:</td>
                  <td>{{ form.order.created_at | dateTimeEnToBr }}h</td>
                </tr>
                <tr>
                  <td class="text-right"><strong>Criada por</strong>:</td>
                  <td>{{ form.order?.created_by_user?.name || '' }}</td>
                </tr>
                </tbody>
              </table>
            </template>
          </el-col>
        </el-row>
        <div>
          <h3 class="mt-2 mb-0">
            Produtos/Serviços ({{ productsList.length }})
          </h3>
          <div class="mb-2" v-if="hasInvoice">
            <span class="mr-2">Mostrar somente Produtos/Serviços incluídos na nota</span>
            <el-switch v-model="showNfProducts"
                       :active-value="true"
                       active-text="SIM"
                       :inactive-value="false"
                       inactive-text="NÃO">
            </el-switch>
          </div>
          <table class="table-show-data">
            <thead>
            <th>Produto/Serviço</th>
            <th class="list-table-nowrap text-center" v-if="showNfProducts">Número<br>do Item</th>
            <th class="text-right">Valor Uni.</th>
            <th class="text-right">Desconto</th>
            <th class="text-right">Valor Final</th>
            <th>Divido?</th>
            <th v-if="hasCommercialReceiptModule || hasCommercialInvoiceModule">Nota Fiscal</th>
            </thead>
            <tbody>
            <tr v-for="product in productsList">
              <td>
                <RouterLink title="Abrir dados do produto/serviço"
                            class="base-link"
                            :to="{name: 'product.update', params: {id: product.product_id}}"
                            target="_blank">
                      <span class="mr-2" title="Produto divido" v-if="product.orders.length > 0">
                          <i class="fas fa-users"></i>
                      </span>
                  <strong>({{ product.quantity }}) {{ product.product.description }}</strong>
                </RouterLink>
              </td>
              <td class="list-table-nowrap text-center" v-if="showNfProducts">
                {{ product.number }}
              </td>
              <td class="text-right">{{ product.price | currencyFormatter }}</td>
              <td class="text-right">
                {{ product.discount | currencyFormatter }}
                <template v-if="Array.isArray(product.plans_discounts)">
                  <div class="fs-12" v-for="discount in product.plans_discounts">
                    <div>
                      <strong>Plano:</strong> {{discount.plan_name}}
                    </div>
                    <div>
                      <strong>Tipo:</strong> {{discount.rule_label}}
                    </div>
                  </div>
                </template>
              </td>
              <td class="text-right">{{ product.final_price | currencyFormatter }}</td>
              <td>
                <span v-if="product.orders.length > 0">Entre {{ product.orders.length }} cliente(s)</span>
                <span v-else>-</span>
              </td>
              <td v-if="hasCommercialReceiptModule || hasCommercialInvoiceModule">
                <template v-if="!!product.invoice">
                  <el-button type="warning"
                             size="mini"
                             @click="showInvoicePdf(product.invoice)"
                             title="Visualizar PDF Nota">
                    <i class="fas fa-file-invoice-dollar"></i>
                    <span class="ml-2">PDF</span>
                  </el-button>
                  <el-button type="info"
                             size="mini"
                             @click="showInvoiceXml(product.invoice)"
                             title="Visualizar XML Nota">
                    <i class="fas fa-file-invoice-dollar"></i>
                    <span class="ml-2">XML</span>
                  </el-button>
                </template>
                <template v-else>
                  Não gerada
                </template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-4" v-if="form.portions.length > 1">
          <div slot="header" class="flex-c">
            <div><strong>Parcelas</strong></div>
            <div class="legends">
              <div class="legend-entry">
                <span class="label label-default"></span>
                <span>Aguardando pgto.</span>
              </div>
              <div class="legend-entry">
                <span class="label label-danger"></span>
                <span>Em Atraso</span>
              </div>
              <div class="legend-entry">
                <span class="label label-success"></span>
                <span>Quitada</span>
              </div>
              <div class="legend-entry">
                <span class="label label-warning"></span>
                <span>Quitada com Atraso</span>
              </div>
            </div>
          </div>
          <div class="responsive-list">
            <table class="custom-table-list">
              <thead>
              <tr>
                <th width="30"></th>
                <th>Parcela</th>
                <th>Descrição</th>
                <th>Data Vencimento</th>
                <th>Data Pagamento</th>
                <th>Forma Pagto.</th>
                <th align="right">Valor</th>
                <th align="right">Valor Quitado</th>
                <th align="right" v-if="isRevenue">Saldo Utilizado</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in form.portions">
                <td>
                  <entry-status-circle :item="item"/>
                </td>
                <td>{{ item.portion }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.due_date | dateEnToBr }}</td>
                <td>{{ item.pay_date | dateEnToBr }}</td>
                <td>{{ item.payment_methods.length ? showPaymentMethods(item) : '' }}</td>
                <td align="right">{{ item.amount | currencyFormatter }}</td>
                <td align="right">
                  <span v-if="item.payment_methods.length">
                      {{ showPaymentMethodsAmountPaid(item) | currencyFormatter }}
                  </span>
                </td>
                <td align="right" v-if="isRevenue">
                  {{ balanceUsed(item) | currencyFormatter }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </template>
  </gripo-dialog>
</template>
