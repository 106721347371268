<script>
import GripoDialog from '@components/common/Dialog'
import ListHeader from '../../../../components/list/Header'
import EntryTellerStatus from './labels/EntryTellerStatus.vue'

import * as restfulService from '../../../../services/restfulService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

export default {
  components: {ListHeader, EntryTellerStatus, GripoDialog},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      filters: {
        type: 'managed',
        year_month: this.yearMonthFormat(null, 'en')
      },
      list: [],
      resume: {
        show: false,
        start_date: '',
        end_date: '',
        entry_teller: {},
        totals: {
          cash: 0,
          system: 0,
          checked: 0,
          diff: 0,
        },
        movements: [],
        payment_methods: [],
      }
    }
  },
  mounted() {
    this.onFilter()
  },
  methods: {
    onFilter() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {...this.filters}
      restfulService.post('entry_teller', 'list', null, data)
          .then(response => {
            this.list = [...response]
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    loadTellerDetails(teller) {
      this.resume.entry_teller.id = teller.id
      if (teller.type === 'default') {
        this.resume.start_date = this.momentNow().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.resume.end_date = this.momentNow().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
      this.loadResume()
    },
    loadResume() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {
        start_date: this.resume.start_date,
        end_date: this.resume.end_date,
      }
      restfulService.post('entry_teller', 'resume', this.resume.entry_teller.id, data)
          .then(response => {
            this.resume.entry_teller = {...response.entry_teller}
            this.resume.totals = {...response.totals}
            this.resume.movements = [...response.movements]
            this.resume.payment_methods = [...response.payment_methods]
            this.resume.show = true
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
            bus.$emit('hide-loader')
          })
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Relatório de Caixas"/>

    <el-card class="el-card__search_list box-card">
      <el-form label-position="top">
        <el-form-item label="Período" style="width: 120px">
          <el-date-picker
              class="el-select-full"
              v-model="filters.year_month"
              type="month"
              format="MM/yyyy"
              value-format="yyyy-MM"
              v-mask="'##/####'"
              placeholder="Mês/Ano"
              @change="onFilter">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <table class="custom-table-list">
        <thead>
        <tr>
          <th class="text-center">Data</th>
          <th class="text-center">Aberto em</th>
          <th class="text-center">Fechado em</th>
          <th class="text-right">Valor Conferido</th>
          <th class="text-right">Valor Sistema</th>
          <th class="text-right">Diferença de Valor</th>
          <th class="text-center">Status</th>
          <th class="btn-actions">Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in list" :key="index">
          <td class="text-center">
            <template v-if="item.type === 'default'">
              Caixa Geral
            </template>
            <template>{{ item.opened_at | dateEnToBr }}</template>
          </td>
          <td class="text-center">
            <template v-if="item.type === 'managed'">
              {{ item.opened_at | timeEnToBr }}h
              <div class="mt-1" v-if="item.data?.user_opened?.id">Por: {{ item.data.user_opened.name }}</div>
            </template>
          </td>
          <td class="text-center">
            <span v-if="item.closed_at">{{ item.closed_at | timeEnToBr }}h</span>
            <div class="mt-1" v-if="item.data?.user_closed?.id">Por: {{ item.data.user_closed.name }}</div>
          </td>
          <td class="text-right">
            <template v-if="item.type === 'managed' && item.status === 'closed'">
              {{ item.data?.final_amount | currencyFormatter }}
            </template>
          </td>
          <td class="text-right">
            <template v-if="item.type === 'managed' && item.status === 'closed'">
              {{ (item.data.final_amount + item.data.final_diff) | currencyFormatter }}
            </template>
          </td>
          <td class="text-right">
            <template v-if="item.type === 'managed' && item.status === 'closed'">
              {{ item.data.final_diff | currencyFormatter }}
            </template>
          </td>
          <td class="text-center">
            <entry-teller-status :status="item.status"/>
          </td>
          <td class="list-table-nowrap">
            <el-button size="mini"
                       title="Detalhes"
                       @click="loadTellerDetails(item)">
              <i class="fas fa-list"></i>
              <span class="ml-2">Detalhes</span>
            </el-button>
          </td>
        </tr>
        </tbody>
      </table>
    </el-card>

    <gripo-dialog :model.sync="resume.show"
                  width="50vw"
                  :persistent="false"
                  :actions-close-btn="false">
      <template slot="header">
        Resumo do Caixa
      </template>
      <template slot="content">
        <template v-if="resume.entry_teller.type === 'managed'">
          <div class="flex-c -jc-sa -ai-fs">
            <div>
              <div class="fs-16">
                Aberto em: <strong>{{ resume.entry_teller?.opened_at | dateTimeEnToBr }}h</strong>
              </div>
              <div class="text-center">
                Usuário: <strong>{{ resume.entry_teller?.data?.user_opened?.name }}</strong>
              </div>
            </div>
            <div v-if="resume.entry_teller?.closed_at">
              <div class="fs-16">
                Fechado em: <strong>{{ resume.entry_teller?.closed_at | dateTimeEnToBr }}h</strong>
              </div>
              <div class="text-center" v-if="resume.entry_teller?.data?.user_closed">
                Usuário: <strong>{{ resume.entry_teller?.data?.user_closed?.name }}</strong>
              </div>
            </div>
          </div>
          <v-divider class="mt-3 mb-3"/>
        </template>
        <template v-if="resume.entry_teller.type === 'default'">
          <h3 class="mb-1">Selecione o período de pesquisa</h3>
          <el-form label-position="top" inline>
            <el-form-item label="Data Inicial">
              <el-date-picker v-model="resume.start_date"
                              placeholder="Data Inicial"
                              type="datetime"
                              format="dd/MM/yyyy HH:mm"
                              size="small"
                              value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Data Final">
              <el-date-picker v-model="resume.end_date"
                              placeholder="Data Final"
                              type="datetime"
                              format="dd/MM/yyyy HH:mm"
                              size="small"
                              value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item class="el-button-form-align">
              <el-button size="mini"
                         type="primary"
                         title="Buscar Dados"
                         @click="loadResume">
                <i class="fas fa-search"></i>
                <span class="ml-2">Buscar Dados</span>
              </el-button>
            </el-form-item>
          </el-form>
        </template>
        <div class="flex-c -jc-sa -ai-fs">
          <div>
            <h3 class="text-center">Comandas e Vendas</h3>
            <div class="mt-2 flex-c gap-20" v-for="paymentMethod in resume.payment_methods">
              <div>({{ paymentMethod.quantity }}) {{ paymentMethod.name }}</div>
              <strong>{{ paymentMethod.total | currencyFormatter }}</strong>
            </div>
          </div>
          <template v-if="resume.entry_teller.type === 'managed'">
            <div></div>
            <div style="max-width: 50%">
              <h3 class="text-center">Movimentações do Caixa</h3>
              <div class="mt-2 flex-c gap-20" v-for="movement in resume.movements">
                <div>{{ movement.created_at | timeEnToBr }}</div>
                <div style="flex: 1;">
                  {{ movement.description }}
                  <div v-if="!!movement.notes">({{ movement.notes }})</div>
                </div>
                <strong v-if="movement.type !== 'close'">
                  <span v-if="movement.type === 'output'">-</span>
                  {{ movement.amount | currencyFormatter }}
                </strong>
              </div>
            </div>
          </template>
        </div>
        <template v-if="!!resume.entry_teller.closed_at || resume.entry_teller.type === 'default'">
          <v-divider class="mt-3 mb-3"/>
          <div class="flex-c -jc-sa -ai-fs">
            <div class="fs-18">
              <div class="flex-c gap-20">
                <div>Total do Sistema:</div>
                <strong class="text-right">
                  {{ resume.totals.system | currencyFormatter }}
                </strong>
              </div>
              <div class="flex-c gap-20" v-if="resume.entry_teller.type === 'managed'">
                <div>Total Conferido:</div>
                <strong class="text-right">
                  {{ resume.totals.checked | currencyFormatter }}
                </strong>
              </div>
              <div class="flex-c gap-20" v-if="resume.entry_teller.type === 'managed'">
                <div>Diferença de Valor:</div>
                <strong class="text-right">
                  {{ resume.totals.diff | currencyFormatter }}
                </strong>
              </div>
            </div>
          </div>
        </template>
      </template>
    </gripo-dialog>

  </div>
</template>
