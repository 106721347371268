<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListEditButton from '../../../../components/list/EditButton'
import ListDeleteButton from '../../../../components/list/DeleteButton'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'
import {confirmDialog} from '@utils/flash'

export default {
  components: {ListMain, ListHeader, ListEditButton, ListDeleteButton},
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      filters: {
        status: 'active'
      },
      dependencies: {}
    }
  },
  mounted() {
    const searchData = {...this.prepareSearchData()}
    bus.$emit('list-init', {domain: 'court', data: {...searchData}}, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    prepareSearchData() {
      const searchData = {}
      if (this.filters.name) {
        searchData.name = {
          'like': '%' + this.filters.name + '%'
        }
      }
      searchData.status = this.filters.status === 'active'
      return searchData
    },
    onFilter() {
      const searchData = {...this.prepareSearchData()}
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {status: 'active'}
      bus.$emit('list-filter-clean', this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    duplicateCourt(court) {
      this.isSubmitted = true
      bus.$emit('show-loader')
      restfulService.post('court', 'duplicate', court.id)
          .then(response => {
            notifyService.success({title: response.message})
            this.$router.push({name: 'court.update', params: {id: response.id}})
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async disabledCourt(court) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente desativar essa quadra?'
      })
      if (confirm) {
        this.isSubmitted = true
        bus.$emit('show-loader')
        restfulService.put('court', null, court.id, {...court, status: false})
            .then(() => {
              notifyService.success()
              this.onFilter()
              this.isSubmitted = false
            })
            .catch(e => {
              this.isSubmitted = false
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Quadras/Espaços" route-name="court.store"></ListHeader>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por nome"
                        @keydown.enter.native.prevent="onFilter"></el-input>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         placeholder="Filtrar por Status"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Ativa" value="active"/>
                <el-option label="Inativa" value="inactive"/>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-nowrap">ID</th>
            <th v-if="false">Clube</th>
            <th>Nome</th>
            <th>Sigla</th>
            <th>Cor</th>
            <th v-if="false">Cidade/Estado</th>
            <th>Status</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.id }}</td>
            <td v-if="false">{{ item.club.name }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.initials }}</td>
            <td>
              <div v-if="item.bg_color" class="color-label -circle" :style="`background-color: ${item.bg_color}`"></div>
              <template v-else>-</template>
            </td>
            <td v-if="false">{{ item.club.city ? item.club.city.name_with_state : '' }}</td>
            <td>{{ item.status ? 'Ativa' : 'Inativa' }}</td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-button type="default"
                           size="small"
                           @click="disabledCourt(item)"
                           :disabled="isSubmitted"
                           title="Desativar Quadra">
                  <i class="far fa-eye-slash"></i>
                </el-button>
                <el-button type="warning"
                           size="small"
                           @click="duplicateCourt(item)"
                           :disabled="isSubmitted"
                           title="Duplicar Quadra">
                  <i class="fas fa-clone"></i>
                </el-button>
                <ListEditButton route-name="court.update" :item="item"></ListEditButton>
                <ListDeleteButton :item="item" domain="court"></ListDeleteButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
