<script>
import ListHeader from '../../../../components/list/Header'
import ListMain from '../../../../components/list/Main'
import ListEditButton from '../../../../components/list/EditButton'
import ListDeleteButton from '../../../../components/list/DeleteButton'
import StatusLabel from '../../../../components/common/StatusLabel.vue'

import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'
import DataService from '@app/services/dataService'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'
import {mapGetters} from 'vuex'

export default {
  components: {ListHeader, ListMain, ListEditButton, ListDeleteButton, StatusLabel},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      filters: {status: ['active', 'created']},
      duplicateQuantity: 1,
      dependencies: {
        coupon_discount_types: []
      },
    }
  },
  async mounted() {
    await this.getDependencies()
    bus.$emit('list-init', {
      domain: 'coupon', data: {...this.prepareSearchData()}, relations: ['target']
    }, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore']),
  },
  methods: {
    prepareSearchData() {
      const searchData = {
        type: 'ecommerce'
      }
      if (this.filters.code) {
        searchData.code = {
          'like': '%' + this.filters.code + '%'
        }
      }
      if (this.filters.stage_id) {
        searchData.target_id = this.filters.stage_id
      }
      if (this.filters.name) {
        searchData.name = {
          'like': '%' + this.filters.name + '%'
        }
      }
      if (this.filters.start_datetime) {
        searchData.start_datetime = {
          '>=': this.filters.start_datetime
        }
      }
      if (this.filters.end_datetime) {
        searchData.end_datetime = {
          '<=': this.filters.end_datetime
        }
      }

      if (this.filters.status.length) {
        searchData.status = {
          'in': this.filters.status
        }
      }
      return searchData
    },
    onFilter() {
      bus.$emit('list-filter', this.prepareSearchData())
    },
    onFilterClean() {
      this.filters = {}
      bus.$emit('list-filter-clean', this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([
          {domain: 'stage', data: {status: 'active'}},
          {domain: 'coupon_discount_type'},
        ]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    getDiscountTypeDescription(coupon) {
      const discountType = this.dependencies.coupon_discount_types.find(item => item.id === coupon.discount_type)
      if (discountType !== undefined) {
        return discountType.description
      }
      return ''
    },
    async onDuplicate(item) {
      bus.$emit('show-loader')
      restfulService.post('coupon', 'duplicate', item.id, {quantity: this.duplicateQuantity})
          .then(() => {
            this.duplicateQuantity = 1
            notifyService.success({hideLoader: true})
            this.onFilter()
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
  },
}
</script>
<template>
  <div>
    <ListHeader title="Cupons" route-name="coupon.store"/>

    <ListMain>
      <div slot="filters">
        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Nome do Cupom">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por nome do cupom"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>

            <el-form-item label="Código do Cupom">
              <el-input v-model="filters.code"
                        placeholder="Filtrar por código do cupom"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>

            <el-form-item label="Torneio">
              <el-select
                  placeholder="Filtrar por torneio"
                  empty-text="Nenhum registro encontrado"
                  v-model="filters.stage_id"
                  class="el-select-full"
                  filterable
                  clearable>
                <el-option v-for="item in dependencies.stages" :key="item.id" :value="item.id" :label="item.name">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Data Inicial">
              <el-date-picker v-model="filters.start_datetime"
                              type="date"
                              placeholder="Data Inicial"
                              format="dd/MM/yyyy"
                              value-format="yyyy-MM-dd"
                              v-mask="'##/##/####'">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="Data Final">
              <el-date-picker v-model="filters.end_datetime"
                              type="date"
                              placeholder="Data Final"
                              format="dd/MM/yyyy"
                              value-format="yyyy-MM-dd"
                              v-mask="'##/##/####'">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="Status">
              <el-select
                  placeholder="Filtrar por status"
                  empty-text="Nenhum registro encontrado"
                  v-model="filters.status"
                  filterable
                  clearable
                  multiple>
                <el-option label="Ativo" value="active"/>
                <el-option label="Cancelado" value="canceled"/>
                <el-option label="Expirado" value="expired"/>
                <el-option label="Criado" value="created"/>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </div><!--slot filters-->

      <div slot="headerTitle">
        <span>Cupons</span>
      </div>

      <div slot="list" v-if="!isLoadingData">
        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-nowrap">Código do Cupom</th>
            <th>Nome do Cupom</th>
            <th>Torneio</th>
            <th>Tipo de Desconto</th>
            <th>Valor</th>
            <th>Utilização</th>
            <th>Data Inicial</th>
            <th>Data Final</th>
            <th>Status</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.code }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.target.name }}</td>
            <td>{{ getDiscountTypeDescription(item) }}</td>
            <td align="right">{{ item.value | currencyFormatter }}</td>
            <td align="right">{{ item.uses_current_count }}/{{ item.uses_limit }}</td>
            <td>{{ item.start_datetime | dateEnToBr }}</td>
            <td>{{ item.end_datetime | dateEnToBr }}</td>
            <td>
              <status-label :status="item.status"/>
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-popover
                    placement="top"
                    width="300">
                  <div>
                    <el-form v-model="filters" label-position="top">
                      <el-form-item label="Quantidade de Cópias">
                        <el-input-number v-model="duplicateQuantity" :min="1" :step="1" />
                      </el-form-item>
                      <el-button type="primary" size="mini" @click="onDuplicate(item)">
                        <i class="fas fa-clone"></i>
                        <span class="ml-1">Duplicar</span>
                      </el-button>
                    </el-form>
                  </div>
                  <el-button slot="reference"
                             type="warning"
                             class="mr-1"
                             title="Duplicar">
                    <i class="fas fa-clone"></i>
                  </el-button>
                </el-popover>
                <ListEditButton route-name="coupon.update" :item="item"/>
                <ListDeleteButton :item="item" domain="coupon"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>