<script>
import GripoDialog from '../../../../components/common/Dialog'
import EmptyContent from '../../../../components/common/EmptyContent'
import ScheduleConfig from './ScheduleConfig'
import ScheduleTypeLabel from './labels/ScheduleType'
import ScheduleTagLabel from './labels/ScheduleTag'
import SchedulePlayers from './labels/SchedulePlayers'
import ScheduleShow from './show/Index'
import ScheduleNotify from './ScheduleNotify'

import {
  defaultSchedule,
  disconfirmScheduleFixed,
  releaseScheduleFixed,
  removeSchedule,
  removeScheduleFixed,
  saveSchedule
} from '../service'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {currencyConfig} from '@utils/money'
import {mapGetters} from 'vuex'

import {required, requiredIf} from 'vuelidate/lib/validators'
import moment from 'moment'
import {cloneDeep, orderBy, debounce, isEmpty} from 'lodash'
import {confirmDialog} from '@utils/flash'
import {getTenantSettings, SETTINGS_TYPES} from "../../../system/tenant/service";

export default {
  components: {
    GripoDialog,
    EmptyContent,
    ScheduleConfig,
    ScheduleTypeLabel,
    ScheduleTagLabel,
    SchedulePlayers,
    ScheduleShow,
    ScheduleNotify,
  },
  mixins: [helpers],
  data() {
    return {
      currentScrollPosition: 0,
      pusherInstance: null,
      isLoadingData: true,
      isSubmitted: false,
      showGrid: true,
      isScheduleConfigModalVisible: false,
      isScheduleModalVisible: false,
      isUnavailableScheduleModalVisible: false,
      isNotifyUserModalVisible: false,
      isSearchingClient: false,
      searchClient: null,
      searchInviteClient: null,
      showModalDatePicker: false,
      showFilters: false,
      courts: [],
      filteredCourts: [],
      scheduleFilterType: null,
      scheduleFilterPayment: 'all',
      selectedTags: [],
      selectedCoaches: [],
      dependencies: {
        courts: [],
        players_class: [],
        clients: [],
        hours: [],
        hours_full: [],
        court_intervals: [],
        players_group: [],
        payment_methods: [],
        plans: [],
        coaches: [],
        sports: [],
      },
      beforeDates: [],
      afterDates: [],
      currentDate: '',
      apiCurrentDate: '',
      groupSelected: '',
      playerSelected: '',
      schedule: {...cloneDeep(defaultSchedule)},
      notifyUser: {
        type: 'save',
        players: [],
        value: false,
        data: {}
      },
      scheduleQueue: {
        client_id: '',
        fixed_court: true,
        fixed_date: true
      },
      selectionEnabled: false,
      optimizedLayout: false,
      selectionList: [],
      filters: {
        not_paid: false
      },
      updateScheduleGrid: {
        originSchedule: {...cloneDeep(defaultSchedule)},
        targetSchedule: {...cloneDeep(defaultSchedule)},
      }
    }
  },
  validations: {
    schedule: {
      court_id: {required},
      date: {required},
      start_hour: {required},
      client_id: {
        required: requiredIf(function () {
          return this.create
        })
      },
      lesson_client_id: {
        required: requiredIf(function () {
          return this.create && this.scheduleLessonType
        })
      }
    }
  },
  computed: {
    ...mapGetters(['tenant', 'roles', 'idle', 'hasProfessionalPlan', 'isCoach', 'currentUser']),
    moneyMask() {
      return currencyConfig()
    },
    onlineScheduleEnabled() {
      return this.tenant?.settings?.app_schedule?.enabled || false
    },
    currentDateFormatted() {
      return this.currentDate ? this.dateFormat(this.currentDate, 'br') : ''
    },
    currentDateWeekday() {
      return this.weekdayString(moment(this.currentDate, 'YYYY-MM-DD').weekday())
    },
    guides: function () {
      return this.dependencies.hours
    },
    guidesMinHeight() {
      return (this.dependencies.hours.length + 2) * 42
    },
    scheduleFixedHeader() {
      return this.guidesMinHeight - 85
    },
    websocketKeyScheduleStatusChange() {
      return 'scheduleStatusChange'
    },
    websocketChannelNameScheduleStatusChangeName() {
      return `${this.websocketKeyScheduleStatusChange}.${this.tenant.id}`
    },
    websocketChannelNameScheduleStatusChangeListen() {
      return `.${this.websocketKeyScheduleStatusChange}`
    },
    scheduleLessonType() {
      return ['lesson', 'lesson-fixed'].includes(this.schedule.type)
    },
    tagsList() {
      const tags = []
      this.courts.filter(item => item.tags.length).map(item => {
        item.tags.map(tag => {
          if (!tags.find(findTag => findTag.id === tag.id)) {
            tags.push({
              id: tag.id,
              name: tag.name
            })
          }
        })
      })
      return tags
    },
    filteredCourtsList() {
      return this.filteredCourts.filter(item => {
        if (this.selectedTags.length) {
          return item.tags.length === 0 || item.tags.some(tag => this.selectedTags.includes(tag.id))
        }
        return true
      })
    },
  },
  watch: {
    'currentDate': function (newValue) {
      if (newValue) {
        this.loadDates()
        this.getSchedules()
      }
    },
    idle: {
      deep: true,
      handler: function (value, oldValue) {
        if (!value.current && oldValue.current && value.seconds > 120) {
          this.getSchedules(true)
        }
      }
    },
    'scheduleFilterType': function () {
      this.filterCourts()
    },
    tagsList: function (value) {
      if (value.length) {
        this.selectedTags = [...value.map(item => item.id)]
      }
    },
    scheduleFilterPayment: function () {
      this.filterCourts()
    }
  },
  async mounted() {
    window.addEventListener('resize', this.resize)
    bus.$on('schedule-config-dialog-change', (value) => this.scheduleConfigDialogChange(value))
    bus.$on('main-container-scroll', (value) => this.mainContainerScrollHandle(value))
    await this.getCurrentDate()
    this.startWebsocket()
    this.getDependencies()
    this.getTenantScheduleSettings()
    this.$refs.schedulesBoxes.addEventListener('scroll', this.handleScheduleBoxesScroll)
    this.$refs.schedulesBoxesContentScrollbarTop.addEventListener('scroll', this.handleSchedulesBoxesContentScrollbarTop)
  },
  beforeDestroy() {
    window.Echo.leaveChannel(this.websocketChannelNameScheduleStatusChangeName)
    window.removeEventListener('resize', this.resize)
    bus.$off('schedule-config-dialog-change', (value) => this.scheduleConfigDialogChange(value))
    bus.$off('main-container-scroll', (value) => this.mainContainerScrollHandle(value))
  },
  methods: {
    getTenantScheduleSettings() {
      return new Promise(async (resolve) => {
        const result = await getTenantSettings(SETTINGS_TYPES.tenant_schedule)
        this.dependencies.sports = result.data.court_settings.sports
        resolve()
      })
    },
    filterCourts() {
      const list = [...this.courts]
      this.filteredCourts.splice(0)
      this.filteredCourts = [...list.map(court => {
        court.hours = [...court.hours.map(hour => {
          hour.hidden = false
          if (this.scheduleFilterType) {
            hour.hidden = hour.type !== this.scheduleFilterType
          }
          if (this.updateScheduleGrid.originSchedule.id && this.updateScheduleGrid.originSchedule.id === hour.schedule.id) {
            hour.hidden = false
          }
          if (Array.isArray(this.updateScheduleGrid.originSchedule.intervals)
              && this.updateScheduleGrid.originSchedule.intervals.length === 1
              && hour.interval !== this.updateScheduleGrid.originSchedule.intervals[0]) {
            hour.hidden = true
          }
          if (this.selectedCoaches.length) {
            hour.hidden = !this.selectedCoaches.includes(hour.schedule.coach_id)
          }
          if (this.scheduleFilterPayment !== 'all' && Array.isArray(hour.schedule.players)) {
            if (this.scheduleFilterPayment === 'partial') {
              hour.hidden = !hour.schedule.players.some(item => item.paid)
            }
            if (this.scheduleFilterPayment === 'paid') {
              hour.hidden = !hour.schedule.players.every(item => item.paid)
            }
          }
          return hour
        })]
        return court
      })]
    },
    resize: debounce(function () {
      this.setSchedulesScrollWidth()
    }, 300),
    setSchedulesScrollWidth() {
      if (this.courts.length) {
        setTimeout(() => {
          if (this.$refs.schedulesBoxes && this.$refs.schedulesBoxes.scrollWidth > this.$refs.schedulesBoxes.clientWidth) {
            this.$refs.schedulesBoxesContentScrollbarTopContent.style.width = `${this.$refs.schedulesBoxes.scrollWidth + 80}px`
          } else {
            this.$refs.schedulesBoxesContentScrollbarTopContent.style.width = 0
          }
        }, 200)
      }
    },
    handleScheduleBoxesScroll($el) {
      this.$refs.schedulesBoxesContentScrollbarTop.scrollLeft = $el.target.scrollLeft
    },
    handleSchedulesBoxesContentScrollbarTop($el) {
      this.$refs.schedulesBoxes.scrollLeft = $el.target.scrollLeft
    },
    toggleFilters() {
      this.showFilters = !this.showFilters
    },
    getDependencies() {
      DataService.get([{domain: 'court'}, {domain: 'player_class'}, {domain: 'player_group'}, {domain: 'court_interval'}, {domain: 'payment_method'}, {domain: 'plan'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
        this.getCoaches()
      })
    },
    async getCoaches() {
      const data = {is_coach: true, query: '', only_clients: true}
      const result = await restfulService.post('client', 'autocomplete', null, data)
      this.dependencies.coaches = [...result]
    },
    async getCurrentDate() {
      return new Promise(async (resolve) => {
        if (this.$route.params && this.$route.params.date) {
          this.currentDate = this.$route.params.date
        } else {
          this.currentDate = await restfulService.get('current-date')
        }
        resolve()
      })
    },
    getSportLabel(schedule) {
      if (!schedule.sport) {
        return ''
      }
      return this.dependencies.sports.find(item => item.id === schedule.sport)?.name || ''
    },
    loadDates() {
      this.beforeDates.splice(0)
      this.afterDates.splice(0)
      let currentDate = moment(this.currentDate, 'YYYY-MM-DD').subtract(2, 'days')
      for (let i = 0; i < 7; i++) {
        if (!currentDate.isSame(moment(this.currentDate, 'YYYY-MM-DD'))) {
          if (currentDate.isSameOrBefore(moment(this.currentDate, 'YYYY-MM-DD'))) {
            this.beforeDates.push({
              date: currentDate.format('YYYY-MM-DD'),
              day: currentDate.format('DD'),
              weekday: currentDate.weekday(),
              weekday_label: this.stringFirstChars(this.weekdayString(currentDate.weekday()), 3)
            })
          } else {
            this.afterDates.push({
              date: currentDate.format('YYYY-MM-DD'),
              day: currentDate.format('DD'),
              weekday: currentDate.weekday(),
              weekday_label: this.stringFirstChars(this.weekdayString(currentDate.weekday()), 3)
            })
          }
        }
        currentDate.add(1, 'days')
      }
    },
    startWebsocket() {
      window.Echo.channel(this.websocketChannelNameScheduleStatusChangeName)
          .listen(this.websocketChannelNameScheduleStatusChangeListen, async ({schedule}) => {
            if (!this.isLoadingData && !this.isScheduleModalVisible) {
              await this.getSchedules(true)
            }
            if (schedule && schedule.local === 'app' && schedule.status !== 'canceled') {
              const dateTimeBr = helpers.methods.dateTimeFormat(schedule.start_datetime, true)
              notifyService.warning({
                title: 'Reserva realizada pelo pelo App',
                message: `${schedule.court.name} - ${dateTimeBr}h`,
              })
            }
          })
    },
    scheduleConfigDialogChange(value) {
      this.isScheduleConfigModalVisible = value
    },
    async getSchedules(force) {
      return new Promise(async (resolve) => {
        bus.$emit('show-loader')
        force = force || false
        this.isLoadingData = true
        const data = {
          date: this.currentDate
        }
        if (data.date !== this.apiCurrentDate || force) {
          restfulService.post('schedules', null, null, data)
              .then(async (response) => {
                if (response.first_hour && response.last_hour) {
                  this.handleHours(response.first_hour, response.last_hour)
                }
                this.apiCurrentDate = response.date
                let scheduleFilterType = this.scheduleFilterType
                this.scheduleFilterType = null
                this.courts = [...response.courts]
                this.filteredCourts = [...response.courts]
                setTimeout(async () => {
                  this.scheduleFilterType = scheduleFilterType
                }, 100)
                this.showGrid = response.show_grid
                this.setSchedulesScrollWidth()
                this.isLoadingData = false
                if (this.selectionEnabled) {
                  this.toggleSelectionEnabled()
                }
                bus.$emit('hide-loader')
                if (this.$route.params && this.$route.params.id !== undefined && this.$route.params.id !== null) {
                  setTimeout(async () => {
                    if (parseInt(this.schedule.id) !== parseInt(this.$route.params.id)) {
                      const schedule = {id: this.$route.params.id}
                      await this.editSchedule(schedule)
                      this.toggleScheduleVisible()
                    }
                  }, 200)
                }
                resolve()
              })
              .catch(e => {
                this.isLoadingData = false
                bus.$emit('hide-loader')
                console.log(e)
                resolve()
              })
        } else {
          bus.$emit('hide-loader')
          this.isLoadingData = false
          resolve()
        }
      })
    },
    handleHours(firstHour, lastHour) {
      const hours = []
      firstHour = firstHour.length === 8 ? moment(firstHour.substr(0, 2) + ':00:00', 'HH:mm') : moment(firstHour, 'YYYY-MM-DD HH:mm:ss')
      lastHour = lastHour.length === 8 ? moment(lastHour.substr(0, 2) + ':00:00', 'HH:mm') : moment(lastHour, 'YYYY-MM-DD HH:mm:ss')
      let currentHour = firstHour
      while (currentHour.isSameOrBefore(lastHour)) {
        let hour = currentHour.format('HH:mm')
        hours.push({
          id: hour,
          name: hour
        })
        currentHour = currentHour.add(1, 'hours')
      }
      hours.push({
        id: currentHour.format('HH:mm'),
        name: currentHour.format('HH:mm')
      })
      this.dependencies.hours = [...hours]
    },
    getScheduleHeight(interval) {
      return 10 + ((interval - 15) / 15 * 10);
    },
    handleScheduleHeight(interval, intervals) {
      if (intervals.length === 0 || intervals.length === 1) {
        return this.getScheduleHeight(interval)
      } else {
        let multipleHeight = 0
        intervals.map(item => {
          multipleHeight += this.getScheduleHeight(item)
          multipleHeight += 1
        })
        return multipleHeight
      }
    },
    getScheduleStyle(hour, interval, index, schedule) {
      return {
        top: this.getSchedulePositionTop(hour, interval) + 'px',
        'z-index': `500${index}`,
        height: this.handleScheduleHeight(interval, schedule?.intervals || []) + 'px',
        'max-height': this.handleScheduleHeight(interval, schedule?.intervals || []) + 'px',
      }
    },
    getSchedulePositionTop(hour) {
      const topInterval = 42
      const [, minutePart,] = hour.split(':').map(Number);
      const hourIndex = this.dependencies.hours.findIndex(item => item.name.substr(0, 2) === hour.substr(0, 2))
      return (hourIndex + (minutePart / 5 * 0.0833)) * topInterval
    },
    filterSelected(filter) {
      return filter === this.scheduleFilterType
    },
    toggleFilterSelected(filter) {
      if (filter === this.scheduleFilterType) {
        this.scheduleFilterType = null
      } else {
        this.scheduleFilterType = filter
      }
    },
    filterTagSelected(tag) {
      return this.selectedTags.includes(tag)
    },
    toggleFilterTagSelected(tag) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags = [...this.selectedTags.filter(item => item !== tag)]
      } else {
        this.selectedTags.push(tag)
      }
    },
    filterCoachSelected(coach) {
      return this.selectedCoaches.includes(coach)
    },
    toggleFilterCoachSelected(coach) {
      if (this.selectedCoaches.includes(coach)) {
        this.selectedCoaches = [...this.selectedCoaches.filter(item => item !== coach)]
      } else {
        this.selectedCoaches.push(coach)
      }
      this.filterCourts()
    },
    async changeDay(type) {
      let date = this.dateCalendarToString(this.currentDate)
      if (type === 'minus') {
        date = moment(date, 'YYYY-MM-DD').subtract(1, 'days')
      } else {
        date = moment(date, 'YYYY-MM-DD').add(1, 'days')
      }
      this.currentDate = await date.format('YYYY-MM-DD')
    },
    async setDate(date) {
      this.currentDate = date
    },
    toggleScheduleVisible() {
      this.isScheduleModalVisible = !this.isScheduleModalVisible
    },
    async handleScheduleVisible() {
      if (this.isScheduleModalVisible) {
        this.groupSelected = ''
        this.playerSelected = ''
        this.$refs.clientComponentPlayerInvite?.reset()
      }
    },
    async handleScheduleClick(type, item, courtId, value) {
      if (this.updateScheduleGrid.originSchedule.id) {
        this.updateScheduleGrid.targetSchedule = {...cloneDeep(this.updateScheduleGrid.originSchedule), ...cloneDeep(item)}
        this.updateScheduleGrid.targetSchedule.date = this.currentDate
        this.updateScheduleGrid.targetSchedule.court_id = courtId
        this.updateScheduleGrid.targetSchedule.start_hour = item.start_hour
        this.updateScheduleGrid.targetSchedule.end_hour = item.end_hour
        this.updateScheduleGrid.targetSchedule.sport = this.updateScheduleGrid.originSchedule.sport
        const originScheduleCourt = this.courts.find(item => item.id === this.updateScheduleGrid.originSchedule.court_id)
        const targetScheduleCourt = this.courts.find(item => item.id === this.updateScheduleGrid.targetSchedule.court_id)
        const originStartDatetime = moment(this.updateScheduleGrid.originSchedule.date + ' ' + this.updateScheduleGrid.originSchedule.start_hour, 'YYYY-MM-DD HH:mm:ss')
        const originEndDatetime = moment(this.updateScheduleGrid.originSchedule.date + ' ' + this.updateScheduleGrid.originSchedule.end_hour, 'YYYY-MM-DD HH:mm:ss')
        const targetStartDatetime = moment(this.currentDate + ' ' + this.updateScheduleGrid.targetSchedule.start_hour, 'YYYY-MM-DD HH:mm:ss')
        const targetEndDatetime = targetStartDatetime.clone().add(originEndDatetime.diff(originStartDatetime, 'minutes'), 'minutes')
        let html = `
            <strong>Horário antigo:</strong><br>
            Quadra: ${originScheduleCourt.name}<br>
        `
        if (this.updateScheduleGrid.originSchedule.date !== this.currentDate) {
          html += `Data: ${this.dateFormat(this.updateScheduleGrid.originSchedule.date)}<br>`
        }
        html += `
              Horário: ${this.updateScheduleGrid.originSchedule.start_hour} até ${this.updateScheduleGrid.originSchedule.end_hour}<br><br>
              <strong>Horário novo:</strong><br>
              Quadra: ${targetScheduleCourt.name}<br>
            `
        if (this.updateScheduleGrid.originSchedule.date !== this.currentDate) {
          html += `Data: ${this.dateFormat(this.currentDate)}<br>`
        }
        html += `Horário: ${this.updateScheduleGrid.targetSchedule.start_hour} até ${targetEndDatetime.format('HH:mm:ss')}`
        const {value: confirm} = await confirmDialog({
          title: 'Deseja realmente alterar essa reserva?',
          icon: 'warning',
          html,
        })
        if (confirm) {
          this.schedule = {...this.schedule, ...cloneDeep(this.updateScheduleGrid.targetSchedule)}
          this.schedule.start_datetime = targetStartDatetime.format('YYYY-MM-DD HH:mm:ss')
          this.schedule.start_hour = targetStartDatetime.format('HH:mm:ss')
          this.schedule.end_datetime = targetEndDatetime.format('YYYY-MM-DD HH:mm:ss')
          this.schedule.end_hour = targetEndDatetime.format('HH:mm:ss')
          this.saveScheduleType()
        }
      } else {
        if (type === 'schedule') {
          await this.handleSchedule(item, courtId, value)
        } else if (type === 'unavailable') {
          await this.handleUnavailableSchedule(item, courtId, value)
        }
      }
    },
    async handleSchedule(item, courtId) {
      bus.$emit('show-loader')
      if (isEmpty(item.schedule)) {
        const schedule = {...cloneDeep(defaultSchedule)}
        schedule.create = true
        schedule.court = this.courts.find(item => item.id === courtId)
        schedule.court_id = courtId
        schedule.date = this.currentDate
        schedule.start_hour = item.start_hour
        schedule.unavailable = item.unavailable || false
        schedule.unavailable_details = item.unavailable_details || {notes: ''}
        if (this.selectionList.length) {
          const selectedSchedules = orderBy(this.selectionList, ['start_hour'])
          const firstSchedule = selectedSchedules[0]
          const lastSchedule = selectedSchedules[selectedSchedules.length - 1]
          const lastHour = moment(`${firstSchedule.date} ${lastSchedule.end_hour}`, 'YYYY-MM-DD HH:mm:ss')
          schedule.end_hour = lastHour.format('HH:mm:ss')
        } else {
          schedule.end_hour = item.end_hour
        }
        this.schedule = {...cloneDeep(defaultSchedule), ...cloneDeep(schedule)}
      } else {
        this.schedule = {...cloneDeep(defaultSchedule), ...cloneDeep(item.schedule)}
      }
      this.toggleScheduleVisible()
    },
    async removeSchedule(schedule, type, confirmation) {
      type = type || null
      confirmation = confirmation || false
      this.schedule = {...cloneDeep(defaultSchedule), ...cloneDeep(schedule)}
      if (type && type === 'releaseFixed') {
        bus.$emit('show-loader')
        const data = {
          id: this.schedule?.id || '',
          court_id: this.schedule.court_id,
          date: this.schedule.date,
          start_hour: this.schedule.start_hour,
          end_hour: this.schedule.end_hour,
          is_multiple: this.selectionEnabled && this.selectionList.length > 0,
          selection: this.selectionList
        }
        releaseScheduleFixed(data)
            .then(() => {
              notifyService.success({message: 'Horário fixo liberado com sucesso.'})
              this.getSchedules(true)
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
            })
      } else if (type && type === 'disconfirmFixed') {
        bus.$emit('show-loader')
        const data = {
          id: this.schedule.id,
          schedule_fixed_id: this.schedule.schedule_fixed_id,
          court_id: this.schedule.court_id,
          date: this.schedule.date,
          start_hour: this.schedule.start_hour,
          end_hour: this.schedule.end_hour,
          is_multiple: this.selectionEnabled && this.selectionList.length > 0,
          selection: this.selectionList
        }
        disconfirmScheduleFixed(data)
            .then(() => {
              notifyService.success({message: 'Horário desconfirmado com sucesso.'})
              this.getSchedules(true)
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
            })
      } else {
        if (confirmation) {
          let title = 'Atenção!'
          let text = 'Deseja realmente cancelar essa reserva?'
          if (this.schedule.fixed && !this.schedule.schedule_fixed) {
            let fixedLabel = 'esse HORÁRIO FIXO'
            if (this.scheduleLessonType) {
              fixedLabel = 'essa AULA FIXA'
            }
            title = `Atenção! Deseja realmente cancelar ${fixedLabel}?`
            text = `Essa ação irá cancelar ${fixedLabel} e não estará mais na grade de horários`
          }
          const {value: confirm} = await confirmDialog({
            title,
            text,
          })
          if (!confirm) {
            return
          }
        }
        this.notifyUser.type = 'remove'
        this.notifyUser.players.splice(0)
        this.schedule.players.map(item => {
          this.notifyUser.players.push({...item, notify: false, type: 'player'})
        })
        if (this.notifyUser.players.length === 0 && this.schedule.fixed) {
          this.notifyUser.players.push({...{client: this.schedule.client}, notify: false, type: 'player'})
        }
        if (this.scheduleLessonType) {
          this.notifyUser.players.push({...{client: this.schedule.coach}, notify: false, type: 'coach'})
        }
        this.isNotifyUserModalVisible = true
      }
    },
    confirmRemoveSchedule() {
      const data = {
        schedule_id: this.schedule.id,
        notify_users: [...this.notifyUser.players],
        is_multiple: this.selectionEnabled && this.selectionList.length > 0,
        selection: this.selectionList
      }
      removeSchedule(data)
          .then(() => {
            this.getSchedules(true)
            if (this.isScheduleModalVisible) {
              this.toggleScheduleVisible()
            }
            this.isNotifyUserModalVisible = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async confirmRemoveScheduleFixed() {
      bus.$emit('show-loader')
      const data = {
        client_id: this.schedule.client_id,
        court_id: this.schedule.court_id,
        weekday: this.schedule.weekday,
        date: this.schedule.date,
        start_time: this.schedule.start_hour,
        end_time: this.schedule.end_hour
      }
      removeScheduleFixed(data)
          .then(() => {
            this.getSchedules(true)
            if (this.isScheduleModalVisible) {
              this.toggleScheduleVisible()
            }
            if (this.isNotifyUserModalVisible) {
              this.isNotifyUserModalVisible = false
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    confirmSchedule(schedule) {
      let editSchedule = true
      if (schedule) {
        editSchedule = false
        this.schedule = {...cloneDeep(defaultSchedule), ...cloneDeep(schedule)}
      }
      const data = {
        is_multiple: this.selectionEnabled && this.selectionList.length > 0,
        selection: this.selectionList
      }
      bus.$emit('show-loader')
      restfulService.put('schedule', 'confirm', this.schedule.id, data)
          .then(() => {
            notifyService.success({message: 'Reserva confirmada com sucesso.'})
            this.getSchedules(true)
            if (editSchedule) {
              this.editSchedule(this.schedule)
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async generateOrders(schedule) {
      if (schedule) {
        this.schedule = {...cloneDeep(defaultSchedule), ...cloneDeep(schedule)}
      }
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente gerar as comandas para os atletas desse horário?',
        text: 'Caso o atleta já possua uma comanda aberta o sistema irá incluir o produto quadra nessa comanda',
        confirmButtonText: 'Sim, gerar!',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {split_product: true}
        restfulService.put('schedule', 'generate-orders', this.schedule.id, data)
            .then(() => {
              notifyService.success({message: 'Comandas geradas com sucesso.'})
              bus.$emit('hide-loader')
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    },
    confirmScheduleFixed(schedule) {
      this.schedule = {...this.schedule, ...schedule}
      this.schedule.status = 'scheduled'
      this.saveScheduleType(false)
    },
    saveScheduleType(updateFixed) {
      updateFixed = updateFixed ?? false
      this.schedule.update_fixed = updateFixed
      this.notifyUser.type = 'save'
      this.notifyUser.players.splice(0)
      this.schedule.players.forEach(item => {
        this.notifyUser.players.push({...item, notify: false, type: 'player'})
      })
      if (this.notifyUser.players.length === 0 && this.schedule.fixed) {
        this.notifyUser.players.push({...{client: this.schedule.client}, notify: false, type: 'player'})
      }
      if (this.scheduleLessonType) {
        this.notifyUser.players.push({...{client: this.schedule.coach}, notify: false, type: 'coach'})
      }
      this.isNotifyUserModalVisible = true
    },
    onSaveSchedule() {
      bus.$emit('show-loader')
      let data = {...cloneDeep(this.schedule)}
      saveSchedule(data, {...this.notifyUser}, [...this.selectionList])
          .then(() => {
            this.getSchedules(true)
            notifyService.success({message: 'Horário salvo com sucesso.'})
            this.resetUpdateScheduleGrid()
            this.isNotifyUserModalVisible = false
            bus.$emit('hide-loader')
          })
          .catch(async (e) => {
            console.log(e)
            this.isNotifyUserModalVisible = false
            bus.$emit('hide-loader')
          })
    },
    async toggleUnavailableScheduleVisible() {
      return new Promise(resolve => {
        this.isUnavailableScheduleModalVisible = !this
            .isUnavailableScheduleModalVisible
        resolve()
      })
    },
    unavailableSchedule() {
      bus.$emit('show-loader')
      const data = cloneDeep({
        id: '',
        court_id: '',
        date: '',
        fixed: false,
        weekday: '',
        notes: '',
        is_multiple: this.selectionEnabled && this.selectionList.length > 0,
        selection: this.selectionList,
        coach_id: this.isCoach ? this.currentUser.tenant_client_id : null
      })
      data.date = this.schedule.date
      data.datetime = this.schedule.date + ' ' + this.schedule.start_hour
      data.notes = this.schedule.unavailable_details
          ? this.schedule.unavailable_details.notes
          : '' || ''
      data.court_id = this.schedule.court_id
      data.weekday = this.schedule.weekday || 0
      data.fixed = false
      if (this.schedule.unavailable_details) {
        data.id = this.schedule.unavailable_details.id || ''
      }
      restfulService
          .post('schedule', 'unavailable', null, data)
          .then(() => {
            this.getSchedules(true)
            notifyService.success({
              message: 'Horário indisponibilizado com sucesso.'
            })
            this.toggleUnavailableScheduleVisible()
            if (this.isScheduleModalVisible) {
              this.toggleScheduleVisible()
            }
            bus.$emit('hide-loader')
          })
          .catch(async e => {
            this.toggleUnavailableScheduleVisible()
            if (this.isScheduleModalVisible) {
              this.toggleScheduleVisible()
            }
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    removeUnavailable() {
      bus.$emit('show-loader')
      const data = cloneDeep({
        unavailable_id: this.schedule.unavailable_details.id,
        is_multiple: this.selectionEnabled && this.selectionList.length > 0,
        selection: this.selectionList,
        coach_id: this.isCoach ? this.currentUser.tenant_client_id : null
      })
      restfulService
          .post('schedule', 'available', null, data)
          .then(() => {
            this.getSchedules(true)
            notifyService.success({message: 'Horário disponibilizado.'})
            this.toggleUnavailableScheduleVisible()
            if (this.isScheduleModalVisible) {
              this.toggleScheduleVisible()
            }
            bus.$emit('hide-loader')
          })
          .catch(async e => {
            this.toggleUnavailableScheduleVisible()
            if (this.isScheduleModalVisible) {
              this.toggleScheduleVisible()
            }
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    async handleUnavailableSchedule(item, courtId, fixed) {
      if (fixed) return //*
      bus.$emit('show-loader')
      this.schedule = {...cloneDeep(defaultSchedule)}
      this.schedule.court = this.courts.find(item => item.id === courtId)
      this.schedule.court_id = courtId
      this.schedule.date = this.currentDate
      this.schedule.start_hour = item.start_hour
      this.schedule.end_hour = item.end_hour
      this.schedule.unavailable = item.unavailable || false
      this.schedule.unavailable_details = item.unavailable_details || {notes: ''}
      this.toggleUnavailableScheduleVisible()
      bus.$emit('hide-loader')
    },
    toggleScheduleConfigVisible() {
      this.isScheduleConfigModalVisible = !this.isScheduleConfigModalVisible
    },
    getCourtBorderColor(court) {
      if (court.bg_color !== 'null' && court.bg_color !== null) {
        return court.bg_color
      }
      return '#CCC'
    },
    getCourtFontColor(court) {
      if (court.bg_color !== 'null' && court.bg_color !== null) {
        return '#FFF'
      }
      return '#000'
    },
    getCourtBgFontColor(court) {
      if (court.bg_color !== 'null' && court.bg_color !== null) {
        return court.bg_color
      }
      return '#f8f8f8'
    },
    getScheduleClasses(item) {
      const typeClass = item.schedule.type + (item.schedule.type === 'lesson' && (item.schedule.fixed || item.schedule.schedule_fixed) ? '-fixed' : '')
      const classes = ['schedule-item']
      classes.push(`-${typeClass}`)
      if (item.available || item.unavailable) {
        classes.push('-free-spot')
        if (item.unavailable) {
          classes.push('-schedule-unavailable')
        }
      }
      if (item.hidden || item.coach_restrict) {
        classes.push('-is-hidden')
      }
      if (this.selectionEnabled) {
        classes.push('-is-selection')
      }
      return classes.join(' ')
    },
    editCourt(court) {
      this.$router.push({name: 'court.update', params: {id: court.id}})
    },
    toggleSelectionEnabled() {
      this.selectionEnabled = !this.selectionEnabled
      if (!this.selectionEnabled) {
        this.selectionList.splice(0)
        this.scheduleFilterType = null
      }
    },
    toggleOptimizedLayout() {
      this.optimizedLayout = !this.optimizedLayout
    },
    isScheduleSelected(value) {
      return this.selectionList.some(item => item.hash === `${value.court_id}-${value.start_hour}-${value.end_hour}`)
    },
    scheduleSelectionChange($event, value) {
      if ($event && !this.isScheduleSelected(value)) {
        this.selectionList.push({
          id: value?.schedule?.id ?? null,
          unavailable_id: value?.unavailable_details?.id ?? null,
          hash: `${value.court_id}-${value.start_hour}-${value.end_hour}`,
          court_id: value.court_id,
          date: this.currentDate,
          datetime: this.currentDate + ' ' + value.start_hour,
          start_hour: value.start_hour,
          end_hour: value.end_hour,
          interval: value.interval,
        })
        this.scheduleFilterType = value.type
      } else {
        this.selectionList = this.selectionList.filter(item => item.hash !== `${value.court_id}-${value.start_hour}-${value.end_hour}`)
      }
      if (this.selectionList.length === 0) {
        this.scheduleFilterType = null
      }
    },
    mainContainerScrollHandle(value) {
      this.currentScrollPosition = value
    },
    updateScheduleSetOrigin(schedule, cancel) {
      cancel = cancel || false
      if (cancel) {
        this.resetUpdateScheduleGrid()
      } else {
        this.updateScheduleGrid.originSchedule = {...this.updateScheduleGrid.originSchedule, ...schedule}
        this.updateScheduleGrid.originSchedule.switched = true
        this.scheduleFilterType = 'available'
      }
    },
    getHourMaxSize(item) {
      if (Array.isArray(item?.schedule?.intervals)) {
        return item.schedule.intervals.reduce((total, item) => total += item, 0)
      }
      return item.interval
    },
    isChangeOriginSelected(schedule) {
      return this.updateScheduleGrid.originSchedule.id === schedule.id
    },
    resetUpdateScheduleGrid() {
      this.updateScheduleGrid.originSchedule = {...cloneDeep(defaultSchedule)}
      this.updateScheduleGrid.targetSchedule = {...cloneDeep(defaultSchedule)}
      this.scheduleFilterType = ''
    }
  }
}
</script>

<template>
  <div>
    <div v-if="showGrid" class="schedules">
      <div class="change-date">
        <div class="box">
          <div></div>
          <div class="dates-list">
            <el-button @click="changeDay('minus')" v-if="$vuetify.breakpoint.xs">
              <i class="fas fa-arrow-left"></i>
            </el-button>
            <template v-if="!$vuetify.breakpoint.xs">
              <el-button v-for="(item, index) in beforeDates"
                         :key="`beforeDate-${index}`"
                         circle
                         class="btn-date-circle"
                         @click="setDate(item.date)">
                <span>{{ item.day }}</span>
                <strong>{{ item.weekday_label }}</strong>
              </el-button>
            </template>
            <div class="dates-box menu-datepicker -schedules">
              <v-menu ref="modalDatePicker"
                      :close-on-content-click="false"
                      v-model="showModalDatePicker"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px">
                <v-text-field slot="activator"
                              v-model="currentDateFormatted"
                              hide-details
                              :label="currentDateWeekday"
                              outline/>
                <v-date-picker v-model="currentDate"
                               @input="showModalDatePicker = false"
                               locale="pt-br"/>
              </v-menu>
            </div>
            <template v-if="!$vuetify.breakpoint.xs">
              <el-button v-for="(item, index) in afterDates"
                         :key="`afterDate-${index}`"
                         circle
                         class="btn-date-circle"
                         @click="setDate(item.date)">
                <span>{{ item.day }}</span>
                <strong>{{ item.weekday_label }}</strong>
              </el-button>
            </template>
            <el-button @click="changeDay('plus')" v-if="$vuetify.breakpoint.xs">
              <i class="fas fa-arrow-right"></i>
            </el-button>
          </div>
          <div class="mr-2 hidden-xs-only">
            <span class="fs-12 font-weight-bold mr-1">Agendamento Online:</span>
            <el-tag type="danger" size="mini" v-if="!onlineScheduleEnabled">Inativo</el-tag>
            <el-tag type="success" size="mini" v-else>Ativo</el-tag>
          </div>
          <v-btn icon @click="toggleFilters" title="Exibir Filtros">
            <v-icon color="grey">fa-filter</v-icon>
          </v-btn>
          <v-menu offset-y left v-if="!isCoach">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="grey">fa-cog</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-tile key="toggleScheduleConfigVisible"
                           @click="toggleScheduleConfigVisible()">
                <v-list-tile-content>
                  <v-list-tile-title>
                    <v-icon color="primary" small>fa-cog</v-icon>
                    <span class="ml-2">Configurações do Agendamento Online</span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile key="selectionEnabled"
                           @click="toggleSelectionEnabled">
                <v-list-tile-content>
                  <v-list-tile-title>
                    <v-icon color="secondary" small>fa-tasks</v-icon>
                    <span class="ml-2">Seleção Multipla de Horários</span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile key="optimizedLayout"
                           @click="toggleOptimizedLayout">
                <v-list-tile-content>
                  <v-list-tile-title>
                    <v-icon color="warning" small>fa-th</v-icon>
                    <span class="ml-2">Otimizar Layout</span>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-container class="pl-0 pr-0 pt-2 pb-1" v-if="showFilters">
        <v-layout class="gap-5">
          <v-flex>
            <div class="schedule-labels">
              <h4 class="mr-2">Tipo de Reserva:</h4>
              <div class="labels mb-1">
                <div class="schedule-type-label-box" @click="toggleFilterSelected('system')">
                  <schedule-type-label type="system"
                                       :selected="filterSelected('system')"/>
                </div>
                <div class="schedule-type-label-box" @click="toggleFilterSelected('fixed')">
                  <schedule-type-label type="fixed"
                                       :selected="filterSelected('fixed')"/>
                </div>
                <div class="schedule-type-label-box" @click="toggleFilterSelected('unavailable')">
                  <schedule-type-label type="unavailable"
                                       :selected="filterSelected('unavailable')"/>
                </div>
              </div>
              <div class="labels">
                <div class="schedule-type-label-box" @click="toggleFilterSelected('app')">
                  <schedule-type-label type="app"
                                       :selected="filterSelected('app')"/>
                </div>
                <div class="schedule-type-label-box" @click="toggleFilterSelected('lesson')">
                  <schedule-type-label type="lesson"
                                       :selected="filterSelected('lesson')"/>
                </div>
                <div class="schedule-type-label-box" @click="toggleFilterSelected('lesson-fixed')">
                  <schedule-type-label type="lesson-fixed"
                                       :selected="filterSelected('lesson-fixed')"/>
                </div>
                <div class="schedule-type-label-box" @click="toggleFilterSelected('app_not_confirmed')">
                  <schedule-type-label v-if="false" type="app_not_confirmed"
                                       :selected="filterSelected('app_not_confirmed')"/>
                </div>
              </div>
            </div>
          </v-flex>
          <v-flex>
            <div class="schedule-labels">
              <h4 class="mr-2">Pagamento:</h4>
              <el-select v-model="scheduleFilterPayment"
                         size="small"
                         placeholder="Filtrar por Pagamento"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Parcialmente Pago" value="partial"/>
                <el-option label="Pago" value="paid"/>
              </el-select>
            </div>
          </v-flex>
          <v-flex v-if="!isCoach && dependencies.coaches.length">
            <div class="schedule-labels">
              <h4 class="mr-2">Professores:</h4>
              <div class="labels">
                <div class="schedule-type-label" v-for="coach in dependencies.coaches"
                     @click="toggleFilterCoachSelected(coach.id)">
                  <schedule-tag-label :selected="filterCoachSelected(coach.id)"
                                      :key="coach.id"
                                      :name="coach.name"/>
                </div>
              </div>
            </div>
          </v-flex>
          <v-flex>
            <div class="schedule-labels">
              <h4 class="mr-2">Esportes:</h4>
              <div class="labels">
                <template v-if="tagsList.length">
                  <div class="schedule-type-label"
                       v-for="tag in tagsList"
                       @click="toggleFilterTagSelected(tag.id)">
                    <schedule-tag-label :selected="filterTagSelected(tag.id)"
                                        :key="tag.id"
                                        :name="tag.name"/>
                  </div>
                </template>
                <div v-else>
                  <div>Nenhum esporte configurado nas quadras</div>
                  <div>(Edite suas quadras e configure os esportes)</div>
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
      <div class="schedules-content">
        <div class="content" ref="schedulesContent"
             :style="{minHeight: guidesMinHeight + 'px', maxHeight: guidesMinHeight + 'px'}">
          <div class="schedules-guide">
            <div class="guides" v-for="(item, index) in guides" :key="index" :data-item="item.name">&nbsp;
            </div>
          </div>
          <div class="schedules-hours">
            <div class="hour" v-for="(item, index) in guides" :key="index">{{ item.name }}</div>
          </div>
          <div class="schedules-boxes-scrollbar-top" ref="schedulesBoxesContentScrollbarTop">
            <div ref="schedulesBoxesContentScrollbarTopContent"></div>
          </div>
          <div class="schedules-boxes" ref="schedulesBoxes">
            <el-card :class="['box-card', {'optimized': optimizedLayout}]"
                     v-for="(court, courtIndex) in filteredCourtsList"
                     :key="courtIndex"
                     :style="{
                       minHeight: guidesMinHeight + 'px',
                       maxHeight: guidesMinHeight + 'px',
                       borderLeftColor: `${getCourtBorderColor(court)}`,
                       borderRightColor: `${getCourtBorderColor(court)}`
                     }">
              <div slot="header"
                   :style="`background-color: ${getCourtBgFontColor(court)}; color: ${getCourtFontColor(court)}`">
                <span class="mr-2 court-name" :title="court.name">{{ court.name }}</span>
                <el-button circle
                           size="mini"
                           v-if="!isCoach"
                           :class="{'-dark': court.bg_color === 'null' || court.bg_color === null}"
                           title="Editar quadra"
                           @click="editCourt(court)">
                  <i class="fas fa-pencil-alt"></i>
                </el-button>
              </div>
              <div class="schedule-fixed-header"
                   :style="`background-color: ${getCourtBgFontColor(court)}; color: ${getCourtFontColor(court)}; top: ${scheduleFixedHeader}px`">
                <span class="mr-2 court-name" :title="court.name">{{ court.name }}</span>
                <el-button circle
                           size="mini"
                           v-if="!isCoach"
                           :class="{'-dark': court.bg_color === 'null' || court.bg_color === null}"
                           title="Editar quadra"
                           @click="editCourt(court)">
                  <i class="fas fa-pencil-alt"></i>
                </el-button>
              </div>
              <div class="item schedule-item-effect"
                   v-for="(item, hourIndex) in court.hours"
                   :style="{...getScheduleStyle(item.hour, item.interval, hourIndex, item.schedule)}"
                   :class="[
                       {'is-focus': item.isFocus},
                       ('-interval') + getHourMaxSize(item),
                       {'is-change-origin': isChangeOriginSelected(item.schedule)}
                   ]"
                   :data-hour="item.hour">
                <el-tooltip effect="dark" placement="right" :disabled="!item.schedule?.sport && !optimizedLayout">
                  <div slot="content">
                    <div class="mb-1" v-if="getSportLabel(item.schedule)">{{getSportLabel(item.schedule)}}</div>
                    <div v-if="optimizedLayout">{{item.hour | hourShort}}</div>
                  </div>
                  <div :class="getScheduleClasses(item)">
                    <div class="schedule-selection" v-if="selectionEnabled">
                      <el-checkbox @change="scheduleSelectionChange($event, item)"
                                   :checked="isScheduleSelected(item)"/>
                    </div>
                    <div class="schedule-time">
                      <div class="schedule-time-hour">
                        {{ item.hour | hourShort }}
                      </div>
                      <div class="schedule-time-icon"
                           v-if="item.schedule.status === 'created' && !item.schedule.payment_enabled"
                           title="Reserva não confirmada">
                        <i class="fa fa-exclamation-circle"></i>
                      </div>
                      <div class="schedule-time-icon"
                           v-if="item.schedule.status === 'created' && !item.schedule.paid && item.schedule.payment_enabled"
                           title="Aguardando Pagamento">
                        <i class="fa fa-dollar-sign"></i>
                      </div>
                      <div class="schedule-time-icon"
                           v-if="item.schedule.fixed && item.schedule.schedule_fixed"
                           title="Reserva Fixa Confirmada">
                        <i class="fas fa-check-circle"></i>
                      </div>
                    </div>
                    <div class="schedule-content"
                         @click="handleScheduleClick(item.unavailable ? 'unavailable' : 'schedule', item, court.id, item.unavailable ? item.unavailable_details.fixed : !item.available)">
                      <template v-if="!item.unavailable && (item.schedule.id || item.schedule.fixed)">
                        <div class="players-names">
                          <div class="names">
                            <schedule-players :players="item.schedule.players"
                                              :client="item.schedule.client"
                                              :coach="item.schedule.coach"
                                              :fixed="item.schedule.fixed"
                                              :grid-interval="item.interval"
                                              show-payment/>
                          </div>
                        </div>
                      </template>
                      <template v-else-if="!item.available && item.unavailable">
                        NÃO DISPONIVEL -
                        {{ item.unavailable_details ? item.unavailable_details.notes : '' }}
                      </template>
                      <template v-else-if="item.available && getHourMaxSize(item) >= 30">
                        Disponível
                      </template>
                    </div>
                    <div class="schedule-actions"
                         v-if="!selectionEnabled || (selectionEnabled && selectionList.length)">
                      <div class="menu-box">
                        <v-menu offset-y left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" small>
                              <v-badge color="warning" :value="item.schedule.queue > 0">
                                <template v-slot:badge>
                                  <span>{{ item.schedule.queue }}</span>
                                </template>
                                <v-icon class="fs-18" color="grey lighten-1">fa-ellipsis-v</v-icon>
                              </v-badge>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-tile
                                v-if="hasProfessionalPlan && item.schedule.id && (item.schedule.fixed === false || item.schedule.schedule_fixed === true) && !selectionEnabled && !updateScheduleGrid.originSchedule.id"
                                key="itemGenerateOrders"
                                @click="generateOrders(item.schedule)"
                                :disabled="isSubmitted">
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  <v-icon color="secondary" small class="list-item-icon -small">fa-warehouse</v-icon>
                                  <span class="ml-2">Gerar Comandas</span>
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile
                                v-if="item.schedule.status === 'created' && !updateScheduleGrid.originSchedule.id"
                                key="itemConfirmSchedule"
                                @click="confirmSchedule(item.schedule)"
                                :disabled="isSubmitted">
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  <v-icon color="primary" small>fa-check</v-icon>
                                  <span
                                      class="ml-2">Confirmar {{ !!item.schedule?.coach_id ? 'Aula' : 'Horário' }}</span>
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                            <template
                                v-if="item.schedule.id && item.schedule.fixed === true && !updateScheduleGrid.originSchedule.id">
                              <template v-if="!item.schedule.schedule_fixed">
                                <v-list-tile @click="confirmScheduleFixed(item.schedule)"
                                             key="confirmScheduleFixed"
                                             :disabled="isSubmitted">
                                  <v-list-tile-content>
                                    <v-list-tile-title>
                                      <v-icon color="success" small class="list-item-icon">fa-check</v-icon>
                                      <span
                                          class="ml-2">Confirmar {{
                                          !!item.schedule?.coach_id ? 'Aula Fixa' : 'Horário Fixo'
                                        }}</span>
                                    </v-list-tile-title>
                                  </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile @click="removeSchedule(item.schedule, 'releaseFixed')"
                                             key="itemReleaseSchedule"
                                             :disabled="isSubmitted">
                                  <v-list-tile-content>
                                    <v-list-tile-title>
                                      <v-icon color="warning" small class="list-item-icon">fa-share-square</v-icon>
                                      <span class="ml-2">Liberar Horário</span>
                                    </v-list-tile-title>
                                  </v-list-tile-content>
                                </v-list-tile>
                              </template>
                              <v-list-tile @click="removeSchedule(item.schedule, 'disconfirmFixed')"
                                           v-else
                                           key="disconfirmFixed"
                                           :disabled="isSubmitted">
                                <v-list-tile-content>
                                  <v-list-tile-title>
                                    <v-icon color="error" small class="list-item-icon">fa-calendar-times</v-icon>
                                    <span
                                        class="ml-2">Desconfirmar {{
                                        !!item.schedule?.coach_id ? 'Aula Fixa' : 'Horário Fixo'
                                      }}</span>
                                  </v-list-tile-title>
                                </v-list-tile-content>
                              </v-list-tile>
                            </template>
                            <v-list-tile @click="removeSchedule(item.schedule, null, item.schedule.fixed)"
                                         key="itemRemoveSchedule"
                                         v-if="item.schedule.id && !selectionEnabled && !updateScheduleGrid.originSchedule.id"
                                         :disabled="isSubmitted">
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  <v-icon color="error" small class="list-item-icon">fa-times</v-icon>
                                  <span
                                      class="ml-2">Cancelar {{ !!item.schedule?.coach_id ? 'Aula' : 'Horário' }}</span>
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile @click="handleUnavailableSchedule(item, court.id)"
                                         key="itemUnavailableSchedule"
                                         v-if="isEmpty(item.schedule) && !item.unavailable && !item.schedule.id && !item.schedule.fixed && ('available' === scheduleFilterType || !scheduleFilterType) && !updateScheduleGrid.originSchedule.id"
                                         :disabled="isSubmitted">
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  <v-icon color="error" small class="list-item-icon">fa-exclamation-circle
                                  </v-icon>
                                  <span class="ml-2">Indisponibilizar Horário</span>
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile @click="handleUnavailableSchedule(item, court.id)"
                                         key="itemUnavailableScheduleAvailable"
                                         v-if="isEmpty(item.schedule) && item.unavailable && ('unavailable' === scheduleFilterType || !scheduleFilterType) && !updateScheduleGrid.originSchedule.id"
                                         :disabled="isSubmitted">
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  <v-icon color="success" small class="list-item-icon">
                                    fa-exclamation-circle
                                  </v-icon>
                                  <span class="ml-2">Tornar Disponível</span>
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile @click="updateScheduleSetOrigin(item.schedule)"
                                         key="updateScheduleSetOrigin"
                                         v-if="item.schedule.id && !selectionEnabled && !updateScheduleGrid.originSchedule.id"
                                         :disabled="isSubmitted">
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  <v-icon small class="list-item-icon">
                                    fa-exchange-alt
                                  </v-icon>
                                  <span class="ml-2">Alterar Quadra/Horário</span>
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile @click="updateScheduleSetOrigin(item.schedule, true)"
                                         key="updateScheduleRemoveOrigin"
                                         v-if="item.schedule.id && !selectionEnabled && updateScheduleGrid.originSchedule.id"
                                         :disabled="isSubmitted">
                              <v-list-tile-content>
                                <v-list-tile-title>
                                  <v-icon small class="list-item-icon">
                                    fa-times
                                  </v-icon>
                                  <span class="ml-2">Cancelar Alteração</span>
                                </v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                </el-tooltip>
              </div>
            </el-card>
          </div>
        </div>
      </div>

      <schedule-show :show.sync="isScheduleModalVisible"
                     :schedule-prop="schedule"
                     :is-coach="isCoach"
                     :courts="courts"
                     :dependencies="dependencies"
                     :selectionList="selectionList"
                     @updateList="getSchedules"
                     @updateListForced="getSchedules(true)"/>

      <schedule-notify :show.sync="isNotifyUserModalVisible"
                       :notify-user="notifyUser"
                       :schedule-fixed="schedule.fixed && !schedule.schedule_fixed"
                       @confirmRemoveScheduleFixed="confirmRemoveScheduleFixed"
                       @confirmRemoveSchedule="confirmRemoveSchedule"
                       @saveSchedule="onSaveSchedule"/>

      <gripo-dialog :model.sync="isUnavailableScheduleModalVisible"
                    width="30vw"
                    top="1vh"
                    :actions-close-btn="false"
                    :on-close="handleScheduleVisible"
      >
        <template v-slot:header>Indisponibilizar Horário</template>
        <template v-slot:content>
          <el-form ref="schedule" :model="schedule" label-position="top">
            <el-row>
              <el-col>
                <h4>Insira uma mensagem</h4>
                <el-input
                    type="textarea"
                    placeholder="Motivo da indisponibilidade do horário"
                    v-model="schedule.unavailable_details.notes"
                ></el-input>
              </el-col>
            </el-row>
          </el-form>
        </template>
        <template v-slot:actions>
          <el-button
              :v-if="(schedule.unavailable === true || scheduleFilterType === 'unavailable')"
              type="secondary"
              @click="removeUnavailable(schedule)"
              :disabled="!schedule.unavailable"
              :loading="isSubmitted"
          >
            <i class="fas fa-check"></i>
            <span>Tornar disponível</span>
          </el-button>
          <v-spacer></v-spacer>
          <el-button
              type="success"
              @click="unavailableSchedule"
              :disabled="isSubmitted"
              :loading="isSubmitted"
          >
            <i class="fas fa-check"></i>
            <span>{{ schedule.unavailable || scheduleFilterType === 'unavailable' ? 'Atualizar' : 'Salvar' }}</span>
          </el-button>
        </template>
      </gripo-dialog>

      <schedule-config v-if="isScheduleConfigModalVisible"
                       @changed="getSchedules(true)"
                       @close="toggleScheduleConfigVisible"></schedule-config>

    </div>
    <empty-content v-else-if="!isLoadingData">
      <template v-slot:content>
        <div class="text-center">
          <h3>Para gerenciar a grade de horários você precisa configurar as quadras e horários</h3>
          <router-link :to="{ name: 'court.index' }"
                       class="el-button el-button--info el-button--text"
                       title="Configurar Quadras">
            Configurar Quadras
          </router-link>
        </div>
      </template>
    </empty-content>
  </div>
</template>
