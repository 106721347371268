<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListTheadColumn from '../../../../components/list/TheadColumn'
import GripoDialog from '../../../../components/common/Dialog'
import BalanceStatusLabel from './BalanceStatusLabel.vue'
import TransactionStatusLabel from './StatusLabel.vue'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '@app/services/dataService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {currencyConfig} from '@utils/money'
import {confirmDialog} from '@utils/flash'
import {transactionFeePercentage, withdrawAmount} from '@utils/pagarMe'

import {mapGetters} from 'vuex'
import {cloneDeep} from 'lodash'

const defaultSort = {orderBy: [{column: 'created_at', direction: 'desc'}]}
const defaultFilters = {
  owner_type: 'all',
  status: 'all',
  manual_approval: 'all',
  client_data: {
    name: '',
    cpf: '',
    email: '',
    phone: ''
  },
  gateway_payment_code: '',
  coupons: []
}

export default {
  components: {
    ListTheadColumn, BalanceStatusLabel, ListMain, ListHeader, GripoDialog, TransactionStatusLabel
  },
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      isModalTransferVisible: false,
      isModalBalanceHistoryVisible: false,
      disablePaymentAccountSelection: false,
      payment_account_id: '',
      filters: {...cloneDeep(defaultFilters)},
      dependencies: {
        tenant_payment_accounts: [],
      },
      cards: {
        waitingFunds: 0,
        balanceAvailable: 0,
      },
      amountToTransfer: 0,
      showInputAmountToTransfer: false,
      inputAmountToTransfer: 0,
      balanceHistory: [],
    }
  },
  async mounted() {
    await this.getDependencies()
    if (this.dependencies.tenant_payment_accounts.length <= 1) {
      this.disablePaymentAccountSelection = true
    }
    let paymentAccount = this.dependencies.tenant_payment_accounts.find(item => item.main)
    if (!paymentAccount) {
      paymentAccount = this.dependencies.tenant_payment_accounts[0] || null
    }
    if (paymentAccount) {
      this.payment_account_id = paymentAccount.id
    }
    this.getBalance()
    const searchData = this.prepareSearchData()
    bus.$emit('list-init', {
      domain: 'transaction',
      relations: ['owner', 'user'],
      data: {...searchData, ...defaultSort}
    }, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore']),
    moneyMask() {
      return currencyConfig()
    },
    hasBalance() {
      return this.cards.balanceAvailable > 0
    },
    hasWaitingFunds() {
      return this.cards.waitingFunds > 0
    },
    totalAmount: function () {
      return this.listStore.listData.reduce((a, b) => a + b.amount, 0)
    },
    totalFee: function () {
      return this.listStore.listData.reduce((a, b) => a + (b.fee_charged || 0), 0)
    },
    totalAmountWithFee: function () {
      return this.listStore.listData.reduce((a, b) => a + (b.final_amount || 0), 0)
    },
    selectedPaymentAccount() {
      return this.dependencies.tenant_payment_accounts.find(item => item.id === this.payment_account_id)
    },
    disabledToCreateTransfer() {
      return this.isSubmitted ||
          this.showInputAmountToTransfer ||
          this.finalAmountToTransfer < 0 ||
          !this.amountToTransferIsAvailable
    },
    amountToTransferIsAvailable() {
      return this.cards.balanceAvailable >= this.currencyParser(this.inputAmountToTransfer)
    },
    finalAmountToTransfer() {
      return this.currencyParser(this.inputAmountToTransfer) - this.pagarMeWithdrawAmount
    },
    pagarMeTransactionFeePercentage() {
      return parseFloat(transactionFeePercentage || 0)
    },
    pagarMeWithdrawAmount() {
      return parseFloat(withdrawAmount || 0)
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'tenant_payment_account'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    async getBalance() {
      return new Promise((resolve, reject) => {
        restfulService.post('transaction', 'balance', null, {payment_account_id: this.payment_account_id})
            .then(async (response) => {
              this.cards.balanceAvailable = response.balance
              this.inputAmountToTransfer = this.currencyFormatter(this.cards.balanceAvailable)
              this.amountToTransfer = this.cards.balanceAvailable
              this.cards.waitingFunds = response.waiting_funds
              resolve()
            })
            .catch(e => {
              console.log(e)
              reject(e)
            })
      })
    },
    prepareSearchData() {
      let searchData = {
        payment_account_id: this.payment_account_id
      }
      if (this.filters.owner_type !== 'all') {
        searchData.owner_type = this.filters.owner_type
        const nameFilter = {name: ''}
        if (this.filters.client_data.name) {
          nameFilter.name = {like: `%${this.filters.client_data.name}%`}
        }
        if (this.filters.owner_type === 'Domain\\Tenant\\Tournament\\Registration\\Registration') {
          if (nameFilter.name) {
            searchData.description = {...nameFilter.name}
          }
        }
        if (this.filters.owner_type === 'Domain\\Tenant\\Management\\Schedule\\Schedule') {
          if (nameFilter.name) {
            searchData['owner.client'] = {has: [nameFilter]}
          }
        }
      }
      if (this.filters.created_dates && this.filters.created_dates.length > 0) {
        let startDate = this.dateFormat(this.filters.created_dates[0], 'en')
        let endDate = this.dateFormat(this.filters.created_dates[1], 'en')
        if (startDate.length > 0 && endDate.length > 0) {
          searchData.created_at = {between: [startDate + ' 00:00:00', endDate + ' 23:59:59']}
        }
      }
      if (this.filters.pay_dates && this.filters.pay_dates.length > 0) {
        let startDate = this.dateFormat(this.filters.pay_dates[0], 'en')
        let endDate = this.dateFormat(this.filters.pay_dates[1], 'en')
        if (startDate.length > 0 && endDate.length > 0) {
          searchData.pay_date = {between: [startDate + ' 00:00:00', endDate + ' 23:59:59']}
        }
      }
      if (this.filters.code) {
        searchData.gateway_code = {
          'like': '%' + this.filters.code + '%'
        }
      }
      if (this.filters.description) {
        searchData.description = {
          'like': '%' + this.filters.description + '%'
        }
      }
      if (this.filters.gateway_payment_code) {
        searchData.gateway_response = {
          'like': '%' + this.filters.gateway_payment_code + '%'
        }
      }
      if (this.filters.status !== 'all') {
        searchData.status = this.filters.status
      }
      if (this.filters.manual_approval !== 'all') {
        searchData.manual_approval = this.filters.manual_approval === 'yes'
      }
      if (this.filters.coupons.length > 0) {
        searchData.coupons = {
          'in_array': this.filters.coupons
        }
      }
      return searchData
    },
    onFilter() {
      const searchData = this.prepareSearchData()
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      const data = {...cloneDeep(defaultFilters), ...cloneDeep(defaultSort)}
      bus.$emit('list-filter-clean', data)
    },
    selectPaymentAccount() {
      this.getBalance()
      this.onFilter()
    },
    async openCreateTransfer() {
      bus.$emit('show-loader', 'Carregando saldo atualizado...')
      await this.getBalance()
      this.isModalTransferVisible = true
      this.showInputAmountToTransfer = false
      bus.$emit('hide-loader')
    },
    createTransfer() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      restfulService.post('transaction', 'create-transfer', null, {
        amount_to_transfer: this.amountToTransfer,
        payment_account_id: this.payment_account_id
      })
          .then(response => {
            setTimeout(async () => {
              await this.getBalance()
              this.isModalTransferVisible = false
              this.isSubmitted = false
              bus.$emit('hide-loader')
              notifyService.success({title: response.message})
            }, 2000)
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    confirmAmountToTransfer() {
      if (this.amountToTransferIsAvailable) {
        this.amountToTransfer = this.currencyParser(this.inputAmountToTransfer)
        this.showInputAmountToTransfer = false
      }
    },
    async cancel(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente cancelar esse pagamento?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put('transaction', 'cancel', item.id, {})
            .then(async () => {
              notifyService.success({message: 'Pagamento online cancelado com sucesso'})
              this.onFilter()
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async refund(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente estornar esse pagamento?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put('transaction', 'refund', item.id, {})
            .then(async () => {
              notifyService.success({message: 'Pagamento online estornado com sucesso'})
              await this.getBalance()
              this.onFilter()
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async checkStatus(item) {
      bus.$emit('show-loader')
      restfulService.put('transaction', 'check-status', item.id, {})
          .then(async () => {
            notifyService.success()
            this.onFilter()
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    showBalanceHistory() {
      bus.$emit('show-loader')
      restfulService.post('transaction', 'balance-history', null, {payment_account_id: this.payment_account_id})
          .then(response => {
            this.balanceHistory = [...response]
            this.isModalBalanceHistoryVisible = true
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
  },
}
</script>

<template>
  <div>

    <ListHeader title="Pagamentos Online"/>

    <div class="flex-c gap-20 mb-4">
      <div class="list-card">
        <div class="text-center">
          <el-form>
            <strong>Conta de Pagamento:</strong>
            <el-form-item class="text-center mb-0">
              <div class="flex-c gap-5">
                <el-select v-model="payment_account_id"
                           :disabled="disablePaymentAccountSelection"
                           placeholder="Conta de Pagamento"
                           size="small"
                           empty-text="Nenhum registro encontrado"
                           class="el-select-full mt-1"
                           @change="selectPaymentAccount">
                  <el-option
                      v-for="item in dependencies.tenant_payment_accounts"
                      :key="item.id"
                      :label="`${item.bank} - ${item.agency}/${item.account}-${item.digit}`"
                      :value="item.id">
                  </el-option>
                </el-select>
                <el-button size="small" class="mt-1" @click="showBalanceHistory" title="Histório de Saque">
                  <i class="fas fa-file-prescription"></i>
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="list-card">
        <div class="text-center">
          <strong>Taxa por Pagamento:</strong>
          <div class="value">{{ pagarMeTransactionFeePercentage }}%</div>
        </div>
      </div>
      <div class="list-card">
        <div class="text-center">
          <strong>Taxa por Saque:</strong>
          <div class="value">{{ pagarMeWithdrawAmount | currencyFormatter }}</div>
        </div>
      </div>
      <div class="list-card">
        <div class="text-center">
          <strong>Saldo Atual:</strong>
          <div class="value">{{ cards.balanceAvailable | currencyFormatter }}</div>
        </div>
      </div>
      <div class="list-card" v-if="!hasBalance && hasWaitingFunds">
        <div class="text-center">
          <strong>Saque Solicitado:</strong>
          <div class="value">{{ cards.waitingFunds | currencyFormatter }}</div>
        </div>
      </div>
      <div class="list-card" v-if="hasBalance">
        <el-button @click="openCreateTransfer">Solicitar Saque</el-button>
      </div>
    </div>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Cód. da Transação">
              <el-input v-model="filters.code"
                        placeholder="Filtrar por Cód. da Transação"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Tipo">
              <el-select v-model="filters.owner_type"
                         placeholder="Filtrar por tipo"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Lançamento" value="Domain\Tenant\Financial\Entry\Entry"/>
                <el-option label="Inscrição" value="Domain\Tenant\Tournament\Registration\Registration"/>
                <el-option label="Reserva" value="Domain\Tenant\Management\Schedule\Schedule"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Data de Criação">
              <el-date-picker
                  v-model="filters.created_dates"
                  type="daterange"
                  format="dd/MM/yyyy"
                  placeholder="Filtrar por datas">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Data de Pagamento">
              <el-date-picker
                  v-model="filters.pay_dates"
                  type="daterange"
                  format="dd/MM/yyyy"
                  placeholder="Filtrar por datas">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         placeholder="Filtrar por status"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Aguardando" value="created"/>
                <el-option label="Pago" value="paid"/>
                <el-option label="Cancelado" value="canceled"/>
                <el-option label="Estornado" value="refunded"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Aprovação Manual">
              <el-select v-model="filters.manual_approval"
                         filterable
                         placeholder="Selecione uma opção"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Sim" value="yes"/>
                <el-option label="Não" value="no"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Descrição">
              <el-input v-model="filters.description"
                        placeholder="Filtrar por descrição"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Cliente">
              <el-input v-model="filters.client_data.name"
                        placeholder="Filtrar por Cliente"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Cód. de Pagamento">
              <el-input v-model="filters.gateway_payment_code"
                        placeholder="Filtrar por Cód. de Pagamento"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Cupons">
              <el-select v-model="filters.coupons"
                         placeholder="Digite os cupons"
                         multiple
                         filterable
                         allow-create
                         default-first-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th>Código</th>
            <th>Tipo</th>
            <th align="center">Status</th>
            <th>Descrição</th>
            <th width="130" align="right">Valor</th>
            <th width="120" align="right">Taxa</th>
            <th width="130" align="right">Valor Final</th>
            <th>
              <list-thead-column field="created_at"
                                 label="Data Criação"
                                 align="left"
                                 @update="onFilter"/>
            </th>
            <th>
              <list-thead-column field="pay_date"
                                 label="Data Pagamento"
                                 align="left"
                                 @update="onFilter"/>
            </th>
            <th class="btn-actions" v-if="false">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>{{ item.gateway_code }}</td>
            <td>{{ item.type_label }}</td>
            <td align="center">
              <div>
                <transaction-status-label :status="item.status" :transaction="item"/>
              </div>
              <div class="pt-1" v-if="item.manual_approval && item.user">
                <strong :title="`${item.user.name} - ${item.user.login}`">
                  <i class="fas fa-user"></i>
                  <span class="ml-1">Aprov. Manual</span>
                </strong>
              </div>
              <div v-else-if="item.can_refund && item.status === 'paid'">
                <el-button type="warning"
                           size="mini"
                           @click="refund(item)"
                           title="Estornar">
                  <i class="fas fa-recycle"></i>
                  <span class="ml-1">Estornar</span>
                </el-button>
              </div>
              <div v-else-if="false && item.status === 'created'">
                <el-button type="info"
                           size="mini"
                           @click="checkStatus(item)"
                           title="Verificar Status">
                  <i class="fas fa-sync"></i>
                  <span class="ml-1">Verificar Status</span>
                </el-button>
              </div>
            </td>
            <td>{{ item.description }}</td>
            <td align="right">
              {{ (item.amount - item.discount) | currencyFormatter }}
            </td>
            <td align="right">
              <span v-if="item.status === 'paid'">
                {{ (item.fee_charged || 0) | currencyFormatter }}
              </span>
            </td>
            <td align="right">
              <span v-if="item.status === 'paid'">
                {{ (item.final_amount || 0) | currencyFormatter }}
              </span>
            </td>
            <td>{{ item.created_at | dateTimeEnToBrFull }}</td>
            <td>
              <span v-if="item.pay_date"></span>{{ item.pay_date | dateTimeEnToBrFull }}
            </td>
            <td class="list-table-nowrap" v-if="false">
              <div class="btn-actions">
                <el-button type="warning"
                           size="mini"
                           v-if="item.status === 'created'"
                           @click="cancel(item)"
                           title="Cancelar">
                  <i class="fas fa-times"></i>
                  <span class="ml-1">Cancelar</span>
                </el-button>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th colspan="4" align="right">Total:</th>
            <th align="right">{{ totalAmount | currencyFormatter }}</th>
            <th align="right">{{ totalFee | currencyFormatter }}</th>
            <th align="right">{{ totalAmountWithFee | currencyFormatter }}</th>
            <th colspan="2"></th>
          </tr>
          </tfoot>
        </table>

      </div>

    </ListMain>

    <gripo-dialog :model.sync="isModalTransferVisible"
                  width="50vw">
      <template v-slot:header>Solicitar Saque</template>
      <template v-slot:content>
        <el-row>
          <el-col :span="12">
            <div class="fs-18 mb-4" v-if="selectedPaymentAccount">
              <strong>Conta Bancária:</strong>
              <div class="value">
                {{ selectedPaymentAccount.bank }} -
                {{ selectedPaymentAccount.agency }}/{{ selectedPaymentAccount.account }}-{{
                  selectedPaymentAccount.digit
                }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="fs-18">
              <strong>Saldo Atual</strong>
              <div class="value">
                {{ cards.balanceAvailable | currencyFormatter }}
              </div>

            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="fs-18">
              <strong>Valor a ser sacado:</strong>
              <div v-if="showInputAmountToTransfer">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <el-input
                        v-model.lazy="inputAmountToTransfer"
                        v-money="moneyMask"
                    />
                  </el-col>
                  <el-col :span="12">
                    <el-button type="success" @click="confirmAmountToTransfer">
                      <i class="fas fa-check"></i>
                      <span>Salvar</span>
                    </el-button>
                  </el-col>
                </el-row>
              </div>
              <div v-else>
                <div class="value">
                  {{ amountToTransfer | currencyFormatter }}
                  <span @click="showInputAmountToTransfer = true">
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="fs-18">
              <strong>Taxa por Saque:</strong>
              <div class="value">{{ pagarMeWithdrawAmount | currencyFormatter }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div class="fs-18 mt-3">
              <strong>Valor final a ser depositado</strong>
              <div class="value">{{ finalAmountToTransfer | currencyFormatter }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="!amountToTransferIsAvailable">
          <el-col>
            <el-alert type="error" class="mt-3 small-alert text-center" :closable="false">
              <div class="fs-14">
                Valor a ser sacado não pode ser maior que o saldo atual disponível
              </div>
            </el-alert>
          </el-col>
        </el-row>

      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   :disabled="disabledToCreateTransfer"
                   @click="createTransfer">
          Confirmar
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="isModalBalanceHistoryVisible"
                  width="70vw">
      <template v-slot:header>Histório de Saques</template>
      <template v-slot:content>
        <el-alert type="warning" class="mb-2 text-center" :closable="false">
          <div class="fs-14">Exibindo as últimas 20 solicitações de saque</div>
        </el-alert>
        <div class="table-responsive">
          <table class="custom-table-list">
            <thead>
            <tr>
              <th>Dt. de Criação</th>
              <th>Dt. de Atualização</th>
              <th>Valor Solicitado</th>
              <th>Status</th>
              <th>Conta Bancária</th>
              <th>Dt. Estimada p/ Depósito</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="history in balanceHistory">
              <td>{{ history.created_at | dateTimeEnToBr }}h</td>
              <td>{{ history.updated_at | dateTimeEnToBr }}h</td>
              <td>{{ history.amount | currencyFormatter }}</td>
              <td>
                <balance-status-label :status="history.status"/>
                <div v-if="history.status === 'failed'">
                  {{ history.error }}
                </div>
              </td>
              <td>
                <div>Documento: {{ history.bank_data.document }}</div>
                <div>Banco: {{ history.bank_data.bank_code }}</div>
                <div>
                  Agênca/CC: {{ history.bank_data.agency }} / {{ history.bank_data.account }}
                  <span v-if="history.bank_data.account_digit">-{{ history.bank_data.account_digit }}</span>
                </div>
              </td>
              <td>{{ history.deposit_date | dateTimeEnToBr }}h</td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
    </gripo-dialog>
  </div>
</template>
